import { AES, enc } from "crypto-js";

const secretKey = "crzAppDefaultKey";

// Helper function for encryption
function encryptObjectToString(obj) {
  const plaintext = JSON.stringify(obj);

  return AES.encrypt(plaintext, secretKey).toString();
}

function decryptToObject(ciphertext) {
  try {
    const bytes = AES.decrypt(ciphertext, secretKey);
    const decryptedText = bytes.toString(enc.Utf8);
    return JSON.parse(decryptedText);
  } catch (error) {
    // console.error("Decryption failed:", error);
    return null;
  }
}

export default { encryptObjectToString, decryptToObject };
