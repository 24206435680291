// YearView.js
import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import PieChart from "./PieChart";

const YearView = ({ date, expensesItems, setViewMode, setCurrentDate }) => {
  const year = date.getFullYear();

  // Get all months of the year
  const monthsArray = Array.from({ length: 12 }, (_, i) => new Date(year, i));

  const yearItems = expensesItems.filter((item) => new Date(item.purchasedDate).getFullYear() === year);
  // Calculate total amount for the year
  const totalYearAmount = yearItems.reduce((total, item) => total + Number(item.amount || 0), 0);

  return (
    <div>
      <h3>
        {year} - Total: ${totalYearAmount.toFixed(2)}
      </h3>
      <Row>
        {monthsArray.map((month) => {
          const formattedMonth = month.toISOString().split("T")[0].slice(0, 7);
          const itemsForMonth = expensesItems.filter((item) => item.purchasedDate.startsWith(formattedMonth));
          const totalAmount = itemsForMonth.reduce((total, item) => total + Number(item.amount || 0), 0);
          if (totalAmount === 0) {
            return;
          }
          return (
            <Col key={month} xs={6} sm={4} md={3} lg={2}>
              <Card
                className="mb-3"
                onClick={() => {
                  setViewMode("month");
                  setCurrentDate(month);
                }}
                style={{ cursor: "pointer" }}
              >
                <Card.Body className="text-center">
                  <p>{month.toLocaleString("default", { month: "long" })}</p>
                  <p>Total: ${totalAmount.toFixed(2)}</p>
                </Card.Body>
              </Card>
            </Col>
          );
        })}
      </Row>
      <Row className="mt-4">
        <PieChart items={yearItems} />
      </Row>
    </div>
  );
};

export default YearView;
