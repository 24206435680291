import React, { useState } from "react";
import { Button, Collapse, Card } from "react-bootstrap";
import CustomTable from "./customTable";
import { EditableModal } from "./editableModal";
import { CREATE_EXPENSE_CONFIGS } from "../configs/createNovelConfig";
import ExpenseTrackingMetadataDB from "../storage/ExpenseTrackingMetadataDB";
import { toast } from "react-toastify";

export default function ExpenseList(props) {
  const { items = [], defaultOpen = true, paginationSize = 20, loadItems = () => {} } = props;
  const [open, setOpen] = useState(defaultOpen);
  return (
    <div style={{ marginTop: "10px" }}>
      <Button
        onClick={() => setOpen(!open)}
        aria-controls="expense-table"
        aria-expanded={open}
        variant="primary"
        style={{ marginBottom: "1rem" }}
      >
        {open ? "Hide Expenses Details" : "Show Expenses Details"}
      </Button>
      <Collapse in={open}>
        <div id="expense-table">
          <Card>
            <Card.Body>
              <CustomTable
                headers={["Title", "Category", "Amount", "purchasedDate", "Update"]}
                colCanSort={[true, true, true, true]}
                paginationSize={paginationSize}
                rows={items.map((item) => {
                  return [
                    { value: item.title },
                    { value: item.category },
                    { value: `$ ${item.amount || 0}` },
                    { value: item.purchasedDate },
                    {
                      value: (
                        <EditableModal
                          title={"Update expense"}
                          configs={CREATE_EXPENSE_CONFIGS.filter((item) => item.fieldName !== "bulkAddItemStr")}
                          actionLabel={"Update"}
                          currentValue={item}
                          actionOnClick={async (data) => {
                            console.log(data);
                            const updatedItem = { ...item, ...data, lastUpdatedDate: new Date().toISOString() };
                            ExpenseTrackingMetadataDB.putItemToExpenseTrackingMetadata(updatedItem);
                            toast.info(`Successfully updated ${updatedItem.title}`);
                            loadItems();
                          }}
                        />
                      ),
                    },
                  ];
                })}
              />
            </Card.Body>
          </Card>
        </div>
      </Collapse>
    </div>
  );
}
