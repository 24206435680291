import React, { useState } from "react";
import { Button, ButtonGroup, Col, Container, Row } from "react-bootstrap";

import DayView from "./DayView";
import MonthView from "./MonthView";
import WeekView from "./WeekView";
import YearView from "./YearView";

const DateNavigator = ({ expensesItems = [{ amount: 0, category: "Gas", purchasedDate: "2024-08-23" }] }) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [viewMode, setViewMode] = useState("month"); // 'day', 'week', 'month', 'year'

  const goToPreviousDay = () => {
    setCurrentDate((prevDate) => new Date(prevDate.setDate(prevDate.getDate() - 1)));
  };

  const goToNextDay = () => {
    setCurrentDate((prevDate) => new Date(prevDate.setDate(prevDate.getDate() + 1)));
  };

  const goToPreviousWeek = () => {
    setCurrentDate((prevDate) => new Date(prevDate.setDate(prevDate.getDate() - 7)));
  };

  const goToNextWeek = () => {
    setCurrentDate((prevDate) => new Date(prevDate.setDate(prevDate.getDate() + 7)));
  };

  const goToPreviousMonth = () => {
    setCurrentDate((prevDate) => new Date(prevDate.setMonth(prevDate.getMonth() - 1)));
  };

  const goToNextMonth = () => {
    setCurrentDate((prevDate) => new Date(prevDate.setMonth(prevDate.getMonth() + 1)));
  };

  const goToPreviousYear = () => {
    setCurrentDate((prevDate) => new Date(prevDate.setFullYear(prevDate.getFullYear() - 1)));
  };

  const goToNextYear = () => {
    setCurrentDate((prevDate) => new Date(prevDate.setFullYear(prevDate.getFullYear() + 1)));
  };

  return (
    <Container>
      <Row className="mb-3">
        <Col>
          <h2>Current Date: {currentDate.toDateString()}</h2>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          <ButtonGroup>
            <Button variant="primary" onClick={() => setViewMode("day")}>
              Day View
            </Button>
            <Button variant="secondary" onClick={() => setViewMode("week")}>
              Week View
            </Button>
            <Button variant="success" onClick={() => setViewMode("month")}>
              Month View
            </Button>
            <Button variant="info" onClick={() => setViewMode("year")}>
              Year View
            </Button>
          </ButtonGroup>
        </Col>
      </Row>

      <Row>
        <Col>
          {viewMode === "day" && (
            <ButtonGroup className="mb-3">
              <Button variant="outline-primary" onClick={goToPreviousDay}>
                Previous Day
              </Button>
              <Button variant="outline-primary" onClick={goToNextDay}>
                Next Day
              </Button>
            </ButtonGroup>
          )}

          {viewMode === "week" && (
            <ButtonGroup className="mb-3">
              <Button variant="outline-secondary" onClick={goToPreviousWeek}>
                Previous Week
              </Button>
              <Button variant="outline-secondary" onClick={goToNextWeek}>
                Next Week
              </Button>
            </ButtonGroup>
          )}

          {viewMode === "month" && (
            <ButtonGroup className="mb-3">
              <Button variant="outline-success" onClick={goToPreviousMonth}>
                Previous Month
              </Button>
              <Button variant="outline-success" onClick={goToNextMonth}>
                Next Month
              </Button>
            </ButtonGroup>
          )}

          {viewMode === "year" && (
            <ButtonGroup className="mb-3">
              <Button variant="outline-info" onClick={goToPreviousYear}>
                Previous Year
              </Button>
              <Button variant="outline-info" onClick={goToNextYear}>
                Next Year
              </Button>
            </ButtonGroup>
          )}
        </Col>
      </Row>

      <Row>
        <Col>
          {viewMode === "day" && <DayView date={currentDate} expensesItems={expensesItems} />}
          {viewMode === "week" && (
            <WeekView
              date={currentDate}
              setViewMode={setViewMode}
              setCurrentDate={setCurrentDate}
              expensesItems={expensesItems}
            />
          )}
          {viewMode === "month" && (
            <MonthView
              date={currentDate}
              setViewMode={setViewMode}
              setCurrentDate={setCurrentDate}
              expensesItems={expensesItems}
            />
          )}
          {viewMode === "year" && (
            <YearView
              date={currentDate}
              expensesItems={expensesItems}
              setViewMode={setViewMode}
              setCurrentDate={setCurrentDate}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default DateNavigator;
