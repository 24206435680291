import { Table, Pagination } from "react-bootstrap";
import { generateRandomId } from "../utils/helperFunctions";
import { useState } from "react";
import { BsSortAlphaDownAlt, BsSortAlphaUpAlt } from "react-icons/bs";

export default function CustomTable(props) {
  const { headers = [], rows = [], colCanSort = [], paginationSize } = props;
  const [sortByCol, setSortByCol] = useState(-1);
  const [sortByDir, setSortByDir] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const sortedRows = [...rows].sort((x, y) => {
    const xvalue = x[sortByCol]?.value || "";
    const yvalue = y[sortByCol]?.value || "";

    return colCanSort[sortByCol] ? sortByDir * xvalue.localeCompare(yvalue, "zh-CN") : 1;
  });

  const totalPages = paginationSize ? Math.ceil(sortedRows.length / paginationSize) : 1;

  const paginatedRows = paginationSize
    ? sortedRows.slice((currentPage - 1) * paginationSize, currentPage * paginationSize)
    : sortedRows;

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <Table responsive borderless striped hover size="sm" className="song_table">
        <thead>
          <tr>
            {headers.map((hd, index) => {
              const isCurrentSortingCol = index === sortByCol;
              return (
                <th
                  key={generateRandomId()}
                  style={{ cursor: colCanSort[index] ? "pointer" : undefined }}
                  onClick={() => {
                    if (isCurrentSortingCol) {
                      setSortByDir(sortByDir * -1);
                    } else {
                      if (colCanSort[index]) setSortByCol(index);
                    }
                  }}
                >
                  {hd}
                  {colCanSort[index] &&
                    isCurrentSortingCol &&
                    (sortByDir === 1 ? <BsSortAlphaUpAlt /> : <BsSortAlphaDownAlt />)}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {paginatedRows.map((row = []) => {
            return (
              <tr key={generateRandomId()}>
                {row.map((col) => {
                  const { onClick, value = "" } = col;
                  return (
                    <td
                      key={generateRandomId()}
                      onClick={onClick ? onClick : undefined}
                      style={{ cursor: onClick ? "pointer" : undefined }}
                    >
                      {value}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
      {paginationSize && totalPages > 1 && (
        <Pagination>
          <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
          <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
          {[...Array(totalPages)].map((_, i) => (
            <Pagination.Item key={i + 1} active={i + 1 === currentPage} onClick={() => handlePageChange(i + 1)}>
              {i + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
          <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
        </Pagination>
      )}
    </>
  );
}
