import axios from "axios";
import Auth from "@aws-amplify/auth";
import { getUserJwtToken } from "../utils/helperFunctions";
import { isUserLogined } from "../context/useAuth";

const SVC_URL = "https://song.service.m.crz97.com/";

const client = axios.create({
  baseURL: SVC_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "x-api-key": "MusicServiceakjhfaskjfbasljsdknsksdkj",
  },
});

const API_PATH = {
  movies: "movies",
};

async function createMovieMetadata({ item, callback }) {
  var jwtToken = null;
  try {
    const user = await Auth.currentAuthenticatedUser();
    jwtToken = getUserJwtToken(user);
  } catch (e) {
    console.log("unable to login", e);
    return;
  }

  client
    .post(
      `${API_PATH.movies}`,
      {
        ...item,
      },
      {
        headers: {
          Authorization: jwtToken ? jwtToken : undefined,
        },
      }
    )
    .then((res) => {
      const { data } = res;
      callback({ data });
    });
}

async function getMoviesByType({ callback, type = "", lastEvaluatedKey = "" }) {
  client
    .get(
      `${API_PATH.movies}?type=${type}&lastEvaluatedKey=${lastEvaluatedKey}`,
      {
        headers: {},
      }
    )
    .then((res) => {
      const {
        data: { movies, lastEvaluatedKey },
        data,
      } = res;
      console.log(data);
      callback({ movies, lastEvaluatedKey });
    })
    .catch((err) => {
      callback({ err });
    });
}

async function getMoviesDetails({ callback, movieId = "" }) {
  client
    .put(`${API_PATH.movies}?movieId=${movieId}`, {
      headers: {},
    })
    .then((res) => {
      const {
        data: { episodes, details },
        data,
      } = res;
      callback({ episodes, details });
    })
    .catch((err) => {
      callback({ err });
    });
}

export default {
  createMovieMetadata,
  getMoviesByType,
  getMoviesDetails,
};
