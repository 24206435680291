import { INPUT_TYPE } from "../../constants";
import { EditableModal } from "../editableModal";

export default function AddCarEvent(props) {
  const { onAddEvent = () => {} } = props;
  const now = new Date();
  const defaultValue = {
    eventType: "Gas Fill",
  };
  return (
    <div>
      <EditableModal
        title={"Add a New Car Event"}
        currentValue={defaultValue}
        configs={[
          {
            fieldName: "eventType",
            label: "Event Type",
            enumValues: [
              { name: "Oil Change", value: "Oil Change" },
              { name: "Tire Rotation", value: "Tire Rotation" },
              { name: "New Tires", value: "New Tires" },
              { name: "Brake Replacement", value: "Brake Replacement" },
              { name: "Battery Replacement", value: "Battery Replacement" },
              { name: "Filter Change", value: "Filter Change" },
              { name: "Gas Fill", value: "Gas Fill" },
              { name: "Other Maintenance", value: "Other Maintenance" },
            ],
            inputType: INPUT_TYPE.SELECT,
            required: true,
          },
          {
            fieldName: "eventDate",
            label: "Event Date",
            inputType: INPUT_TYPE.DATE,
            required: true,
          },
          {
            fieldName: "carMileage",
            label: "Mileage at Event",
            inputType: INPUT_TYPE.NUMBER,
            required: true,
          },
          {
            fieldName: "eventCost",
            label: "Event Cost(Price)",
            inputType: INPUT_TYPE.NUMBER,
          },
          {
            fieldName: "notes",
            label: "Notes (optional)",
            inputType: INPUT_TYPE.TEXT_AREA,
          },
        ]}
        actionLabel={"Log Event"}
        actionOnClick={async (data) => {
          onAddEvent({ ...defaultValue, ...data });
        }}
      />
    </div>
  );
}
