import { useContext, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import uuid from "react-uuid";

import AddNewCar from "../components/CarStatsComponents/AddNewCar";
import CarList from "../components/CarStatsComponents/CarList";
import AppContext from "../context/app-context";
import CarMetadataDB from "../storage/CarMetadataDB";
import CarDetails from "../components/CarStatsComponents/CarDetails";
import userProfileService from "../services/userProfileService";

export function CarsTrackingPage(props) {
  const [trackingItems, setTrackingItems] = useState([]);
  const { userProfile, setLoading } = useContext(AppContext);
  const [query, setQuery] = useState("");
  const [viewingItem, setViewingItem] = useState(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const shareStr = searchParams.get("id");

  async function loadItems() {
    setLoading(true);
    const items = await CarMetadataDB.getAllCarItems();
    const onlineItemsResponse = await userProfileService.getCarDetails({});
    const { data: { carDetailsItems: { items: onlineItems = [] } = {} } = {} } = onlineItemsResponse;
    onlineItems.forEach((item) => {
      if (!items.find((ofItem) => ofItem.id === item.carId)) {
        const { carId, ...attrs } = item;
        items.push({ ...attrs, id: carId });
      }
    });
    setTrackingItems(items);
    setLoading(false);
  }
  async function addNewCar(carAttrs) {
    const id = uuid();
    const createdDate = new Date().toISOString();
    const newCarObj = { ...carAttrs, id, createdDate };
    await CarMetadataDB.putCarItem(newCarObj);
    const ret = await userProfileService.putCarDetails({ updatedItem: newCarObj });
    console.log({ ret });
    loadItems();
  }
  useEffect(() => {
    loadItems();
  }, []);
  const filteredListItems = trackingItems.filter((song) => {
    return JSON.stringify(song).toLowerCase().includes(query.toLowerCase());
  });

  if (viewingItem) {
    return (
      <div>
        <Button onClick={() => setViewingItem(null)}>Go Back</Button>
        <CarDetails
          carDetails={viewingItem}
          onUpdateCarAtt={(attrsChanges) => {
            const newItem = { ...viewingItem, ...attrsChanges };
            CarMetadataDB.putCarItem({ ...newItem });
            setViewingItem(newItem);
          }}
        />
      </div>
    );
  }
  return (
    <div>
      <div style={{ margin: "10px 0", display: "flex", justifyContent: "space-around", flexWrap: "wrap" }}>
        {
          <AddNewCar
            onAdd={(newCar) => {
              // add to db
              console.log(newCar);
              addNewCar(newCar);
            }}
          />
        }
      </div>
      <div>
        <Form.Label>Search</Form.Label>
        <div style={{ display: "flex" }}>
          <Form.Control
            type="text"
            value={query}
            onChange={(e) => {
              const val = e.target.value;
              setQuery(val);
            }}
          />
        </div>
      </div>
      <div>
        <CarList
          items={trackingItems}
          onClickItem={(item) => {
            setViewingItem(item);
          }}
        />
      </div>
    </div>
  );
}
