import { useEffect, useRef, useState } from "react";
import axios from "axios";
import CollectionList from "../components/collectionList";
import VideoPlayer from "../components/videoPlayer";
import { generateRandomId } from "../utils/helperFunctions";
import { BsFillTrash3Fill } from "react-icons/bs";
import { FcDownload } from "react-icons/fc";
import { CircularProgressbar } from "react-circular-progressbar";
import movieService from "../services/movieService";
import { useParams } from "react-router-dom";
import musicService from "../services/musicService";
import metadataDB from "../storage/metadataDB";
import localFileStorage from "../storage/localFileStorage";
import { Button, ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";

export const BACKEND_URL = "http://192.168.86.32:1212";
//aws s3 sync ./ s3://movie-file-bucket-prod-us-east-1/d860307a-404e-8424-c22d-05eb0df12102
function MovieDetailPage() {
  const { movieId } = useParams("movieId");
  const [movieInfo, setmovieInfo] = useState({});
  const [availableEpisodes, setavailableEpisodes] = useState([]);
  const [downloadedEpisodes, setdownloadedEpisodes] = useState([]);
  const [downloadTasks, setdownloadTasks] = useState([]);
  const [playingEpisode, setplayingEpisode] = useState({});
  const [downloadStatus, setDownloadStatus] = useState({});
  const [movieUrl, setmovieUrl] = useState("");
  async function loadDownloadedEpisodes() {
    const episodes = await metadataDB.getMovieEpisodeById(movieId);

    setdownloadedEpisodes(
      episodes.filter((item) => item.downloadStatus !== "START")
    );
  }
  async function loadMoveInfoFromlocal() {
    const info = await metadataDB.getMovieMetadataById(movieId);
    setmovieInfo(info);
  }
  useEffect(() => {
    movieService.getMoviesDetails({
      movieId,
      callback: ({ episodes, details }) => {
        if (!details) {
          return;
        }
        setmovieInfo(details);
        setavailableEpisodes(episodes);
        metadataDB.putItemToMovieMetadata(details);
      },
    });
    loadMoveInfoFromlocal();
    loadDownloadedEpisodes();
  }, []);

  useEffect(() => {
    if (Object.keys(downloadStatus).length === 0 && downloadTasks.length > 0) {
      console.log("Starting a new task", downloadTasks);
      const downloadFirst = downloadTasks.shift();
      downloadItem(downloadFirst);
      setdownloadTasks(downloadTasks);
    }
  }, [downloadStatus]);
  const { title, description } = movieInfo;
  const displayEpisotes = availableEpisodes;
  downloadedEpisodes.forEach((item) => {
    if (!displayEpisotes.find((cur) => cur.key === item.episodeId)) {
      displayEpisotes.push(item);
    }
  });

  async function downloadItem(item) {
    if (Object.keys(downloadStatus).includes(item.key)) {
      console.log("Already starting to download");
      return;
    }
    const { name, type, key: episodeId, bucketName } = item;
    const { url } = await musicService.getPresignedDownloadUrl({
      bucket: bucketName,
      key: episodeId,
      callback: () => {},
    });
    await metadataDB.putItemToEpisodeData({
      ...item,
      episodeId,
      movieId,
      downloadStatus: "START",
    });
    await localFileStorage.getFileOrDownloadedUrl(
      episodeId,
      url,
      type,
      ({ id, precentage }) => {
        setDownloadStatus((prev) => ({
          ...prev,
          [episodeId]: precentage,
        }));
      }
    );
    await metadataDB.putItemToEpisodeData({
      ...item,
      episodeId,
      movieId,
      downloadStatus: "DONE",
    });
    setDownloadStatus((prev) => {
      const { [episodeId]: finishId, ...rest } = prev;
      return rest;
    });
    loadDownloadedEpisodes();
  }
  async function playItem(item) {
    const { name, type, key: episodeId, bucketName } = item;
    const alreadyDownLoad = downloadedEpisodes.find(
      (item) => item.episodeId === episodeId
    );
    setplayingEpisode(item);
    if (alreadyDownLoad) {
      const url = await localFileStorage.getBlobUrlFromLocal(episodeId);
      console.log("get url from local", url);
      setmovieUrl(url);
      return;
    }
    const { url } = await musicService.getPresignedDownloadUrl({
      bucket: bucketName,
      key: episodeId,
    });
    setmovieUrl(url);
  }
  return (
    <div className="container">
      <div style={{ margin: "15px 0" }}>
        <h3>{title}</h3>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {displayEpisotes.map((item) => {
            const { name, type, key: episodeId, bucketName } = item;
            const alreadyDownLoad = downloadedEpisodes.find(
              (item) => item.episodeId === episodeId
            );
            return (
              <div
                className="box"
                key={generateRandomId()}
                style={{
                  backgroundColor:
                    playingEpisode.key === episodeId ? "yellow" : "white",
                  margin: "5px",
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "5px",
                  border: "inset",
                  cursor: "pointer",
                }}
              >
                <div
                  onClick={async () => {
                    playItem(item);
                  }}
                  style={{ padding: "5px 12px" }}
                >
                  {name}
                </div>
                <div
                  style={{ padding: "0 8px" }}
                  onClick={async () => {
                    if (alreadyDownLoad) {
                      // await localFileStorage.deleteBlobFromLocal(episodeId);
                      // await metadataDB.deleteItemFromEpisodeData(episodeId);
                      // loadDownloadedEpisodes();
                    } else {
                      downloadItem(item);
                    }
                  }}
                >
                  {Object.keys(downloadStatus).includes(episodeId) && (
                    <div style={{ width: 30, height: 30 }}>
                      <CircularProgressbar
                        value={downloadStatus[episodeId]}
                        text={`${downloadStatus[episodeId]}%`}
                      />
                    </div>
                  )}

                  {!Object.keys(downloadStatus).includes(episodeId) && (
                    <div>
                      {downloadTasks.find((item) => item.key === episodeId) ? (
                        "Pending"
                      ) : alreadyDownLoad ? (
                        <div>
                          <ButtonGroup>
                            <DropdownButton
                              as={ButtonGroup}
                              title=""
                              id="bg-nested-dropdown"
                            >
                              <Dropdown.Item
                                onClick={async () => {
                                  await localFileStorage.deleteBlobFromLocal(
                                    episodeId
                                  );
                                  await metadataDB.deleteItemFromEpisodeData(
                                    episodeId
                                  );
                                  loadDownloadedEpisodes();
                                }}
                              >
                                <BsFillTrash3Fill />{" "}
                              </Dropdown.Item>
                            </DropdownButton>
                          </ButtonGroup>
                        </div>
                      ) : (
                        <FcDownload />
                      )}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <VideoPlayer
          source={movieUrl}
          playNext={() => {
            var currentIndex = availableEpisodes.findIndex(
              (item) => item.key === playingEpisode.key
            );
            if (currentIndex <= availableEpisodes.length - 1) {
              playItem(availableEpisodes[currentIndex + 1]);
            }
            currentIndex = downloadedEpisodes.findIndex(
              (item) => item.key === playingEpisode.key
            );
            if (currentIndex <= downloadedEpisodes.length - 1) {
              playItem(downloadedEpisodes[currentIndex + 1]);
            }
          }}
        />
      </div>
      <div style={{ margin: "15px 0" }}>
        <p>{description}</p>
        <Button
          onClick={() => {
            const itemsToDownLoad = availableEpisodes
              .map((item) => {
                if (
                  !downloadedEpisodes.find((item2) => item2.key === item.key)
                ) {
                  return item;
                }
                return null;
              })
              .filter((item) => item !== null);
            if (itemsToDownLoad.length > 0) {
              const downloadFirst = itemsToDownLoad.shift();
              console.log({ downloadFirst, itemsToDownLoad });
              downloadItem(downloadFirst);
              setdownloadTasks(itemsToDownLoad);
            }
          }}
        >
          Download All
        </Button>
      </div>
    </div>
  );
}

export default MovieDetailPage;
