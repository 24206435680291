import axios from "axios";
import Auth from "@aws-amplify/auth";
import { getUserJwtToken, handleAPICallError } from "../utils/helperFunctions";
import { isUserLogined } from "../context/useAuth";

const SVC_URL = "https://song.service.m.crz97.com/";

const client = axios.create({
  baseURL: SVC_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "x-api-key": "MusicServiceakjhfaskjfbasljsdknsksdkj",
  },
});
const youtubeClient = axios.create({
  baseURL: "https://temp.service.m.crz97.com/",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "x-api-key": "YoutubeServicelkajhsdkashflkasnfjkashf",
  },
});

const API_PATH = {
  resource: "resource",
  song: "song",
  playlist: "playlist",
};

async function createSongMetadata({
  title,
  album,
  artist,
  files,
  filesUploadCallback,
  callback,
  youtubeUrl,
}) {
  // const file = attachments[0];
  var jwtToken = null;
  try {
    const user = await Auth.currentAuthenticatedUser();
    jwtToken = getUserJwtToken(user);
  } catch (e) {
    // console.log(e);
    callback({ err: "you need to login" });
    return;
  }
  // console.log("Creating jwtToken for " + jwtToken);
  const attachments = [];
  if (youtubeUrl) {
    const fileType = "audio/mpeg";
    const { preSignedURL, bucket, key } = await getPresignedUrl({
      // jwtToken,
      fileName: "abcd.mp3",
      fileType: fileType,
    });
    await youtubeClient
      .post(
        `download`,
        {
          presignUrl: preSignedURL,
          youtubeUrl: youtubeUrl,
        },
        {
          headers: {
            Authorization: jwtToken,
          },
        }
      )
      .then((res) => {
        const {
          // data: { collections },
          data,
        } = res;
        console.log(data);
      })
      .catch((err) => {
        handleAPICallError({ callback, err });
        return;
      });
    attachments.push({ bucket, key, name: key, type: fileType });
  } else {
    for (const file of files) {
      const { name, type, size } = file;
      const { preSignedURL, bucket, key } = await getPresignedUrl({
        // jwtToken,
        fileName: name,
        fileType: type,
      });
      console.log({ preSignedURL });
      const res = await axios.put(preSignedURL, file, {
        headers: { "Content-Type": file.type },
        onUploadProgress: (progressEvent) => {
          const uploadPercentage = Math.ceil(
            (progressEvent.loaded / progressEvent.total) * 100
          );
          filesUploadCallback({ id: name, value: uploadPercentage });
        },
      });
      // console.log(res);
      attachments.push({ bucket, key, name, type, size });
    }
  }
  console.log(attachments);

  client
    .post(
      `${API_PATH.song}`,
      {
        title,
        album,
        artist,
        attachment: attachments[0],
      },
      {
        headers: {
          Authorization: jwtToken ? jwtToken : undefined,
        },
      }
    )
    .then((res) => {
      const {
        // data: { collections },
        data,
      } = res;
      callback({ data });
    })
    .catch((err) => {
      handleAPICallError({ callback, err });
      return;
    });
}

async function getSongsByTitle({ callback, title, lastEvaluatedKey = "" }) {
  // const user = await Auth.currentAuthenticatedUser();
  // const jwtToken = getUserJwtToken(user);
  client
    .get(
      `${API_PATH.song}?title=${title}&lastEvaluatedKey=${lastEvaluatedKey}`,
      {
        headers: {},
      }
    )
    .then((res) => {
      const {
        data: { Items, lastEvaluatedKey },
        data,
      } = res;
      callback({ songs: Items, lastEvaluatedKey });
    })
    .catch((err) => {
      handleAPICallError({ callback, err });
      return;
    });
}

async function getPresignedUrl({ fileName, fileType }) {
  // const user = await Auth.currentAuthenticatedUser();
  // const jwtToken = getUserJwtToken(user);
  return await client
    .get(`resource?fileName=${fileName}&fileType=${fileType}`, {
      headers: {
        // Authorization: jwtToken,
      },
    })
    .then((res) => {
      const {
        data: { preSignedURL, bucket, key },
      } = res;
      return { preSignedURL, bucket, key };
    });
}

async function getPresignedDownloadUrl({ bucket, key, callback = () => {} }) {
  // const user = await Auth.currentAuthenticatedUser();
  // const jwtToken = getUserJwtToken(user);
  return await client
    .post(
      `resource`,
      {
        bucket,
        key,
      },
      {
        headers: {
          // Authorization: jwtToken,
        },
      }
    )
    .then((res) => {
      const {
        data: { url },
      } = res;
      callback({ url });
      return { url };
    })
    .catch((err) => {
      handleAPICallError({ callback, err });
      return;
    });
}

async function addSongToPlaylist({ id, songId, callback }) {
  const user = await Auth.currentAuthenticatedUser();
  if (!user) return;
  const jwtToken = getUserJwtToken(user);
  client
    .post(
      `${API_PATH.playlist}`,
      {
        id,
        songId,
      },
      {
        headers: {
          Authorization: jwtToken,
        },
      }
    )
    .then((res) => {
      const { data } = res;
      callback({ data });
    })
    .catch((err) => {
      handleAPICallError({ callback, err });
      return;
    });
}
async function getSongsByPlaylist({ callback, id, lastEvaluatedKey }) {
  const user = await Auth.currentAuthenticatedUser();
  const jwtToken = getUserJwtToken(user);
  client
    .get(`${API_PATH.playlist}?id=${id}&lastEvaluatedKey=${lastEvaluatedKey}`, {
      headers: {
        Authorization: jwtToken,
      },
    })
    .then((res) => {
      const {
        data: { Items, lastEvaluatedKey, playlistIds },
        data,
      } = res;
      callback({ songs: Items, lastEvaluatedKey, playlistIds });
    })
    .catch((err) => {
      handleAPICallError({ callback, err });
      return;
    });
}

async function deleteSongsFromPlaylist({ callback, id, songId }) {
  const user = await Auth.currentAuthenticatedUser();
  const jwtToken = getUserJwtToken(user);
  client
    .delete(`${API_PATH.playlist}?id=${id}&songId=${songId}`, {
      headers: {
        Authorization: jwtToken,
      },
    })
    .then((res) => {
      const { data } = res;
      callback({ data });
    })
    .catch((err) => {
      handleAPICallError({ callback, err });
      return;
    });
}

export default {
  createSongMetadata,
  getSongsByTitle,
  getPresignedDownloadUrl,
  getSongsByPlaylist,
  addSongToPlaylist,
  deleteSongsFromPlaylist,
};
