// src/utils/audioStorage.js
// src/utils/audioStorage.js
import { openDB } from "idb";

const DB_NAME = "musicAppAudio";
const AUDIO_STORE_NAME = "audio";

async function getDb() {
  return openDB(DB_NAME, 1, {
    upgrade(db) {
      db.createObjectStore(AUDIO_STORE_NAME);
    },
  });
}

async function saveAudioBlob(id, blob) {
  const db = await getDb();
  return db.put(AUDIO_STORE_NAME, blob, id);
}

async function getAudioBlob(id) {
  const db = await getDb();
  return db.get(AUDIO_STORE_NAME, id);
}

async function deleteAudioBlob(id) {
  const db = await getDb();
  return db.delete(AUDIO_STORE_NAME, id);
}

export default { saveAudioBlob, getAudioBlob, deleteAudioBlob };
