import React, { useState } from "react";
import { Button, Card, Collapse } from "react-bootstrap";
import CustomTable from "../customTable";

export default function CarList(props) {
  const { items = [], defaultOpen = true, paginationSize = 20, onClickItem = () => {} } = props;
  const [open, setOpen] = useState(defaultOpen);
  return (
    <div style={{ marginTop: "10px" }}>
      {/* <Button
        onClick={() => setOpen(!open)}
        aria-controls="expense-table"
        aria-expanded={open}
        variant="primary"
        style={{ marginBottom: "1rem" }}
      >
        {open ? "Hide Expenses Details" : "Show Expenses Details"}
      </Button> */}
      <Collapse in={open}>
        <div id="expense-table">
          <Card>
            <Card.Body>
              <CustomTable
                headers={["Car Make", "Car Model", "Year", "Mileage (in miles)", "License Plate"]}
                colCanSort={[true, true, true, true]}
                paginationSize={paginationSize}
                rows={items.map((item) => {
                  return [
                    { value: item.carMake, onClick: () => onClickItem(item) },
                    { value: item.carModel, onClick: () => onClickItem(item) },
                    { value: item.carYear, onClick: () => onClickItem(item) },
                    { value: item.carMileage, onClick: () => onClickItem(item) },
                    { value: item.carLicensePlate, onClick: () => onClickItem(item) },
                  ];
                })}
              />
            </Card.Body>
          </Card>
        </div>
      </Collapse>
    </div>
  );
}
