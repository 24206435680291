import Card from "react-bootstrap/Card";

export default function CollectionList(props) {
  const { collections = [], query = "", onClick = () => {} } = props;

  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      {collections
        .filter((c) => {
          if (query.length !== 0) {
            const { title } = c;
            return title.includes(query);
          }
          return true;
        })
        .map((collection) => {
          const { title, description, attachmentUrl } = collection;
          return (
            <div
              key={Math.random()}
              onClick={(e) => {
                onClick(collection);
              }}
              style={{ margin: "5px", cursor: "pointer" }}
            >
              <Card style={{ width: "18rem" }}>
                <Card.Body>
                  <Card.Title>{title}</Card.Title>
                  {/* <Card.Text>{description}</Card.Text> */}
                </Card.Body>
              </Card>
            </div>
          );
        })}
    </div>
  );
}
