import React from "react";
import { Card, ListGroup, Row } from "react-bootstrap";
import PieChart from "./PieChart";

const DayView = ({ date, expensesItems }) => {
  // Format the current date to match the purchasedDate format in expensesItems
  const formattedDate = date.toISOString().split("T")[0];

  // Filter items by the current date
  const itemsForDay = expensesItems.filter((item) => item.purchasedDate === formattedDate);

  // Calculate the total amount for the day
  const totalAmount = itemsForDay.reduce((total, item) => total + Number(item.amount || 0), 0);

  return (
    <Card>
      <Card.Body>
        <h3>Day View</h3>
        <p>{date.toDateString()}</p>
        <p>Total Amount: ${totalAmount.toFixed(2)}</p>
        {itemsForDay.length > 0 ? (
          <ListGroup>
            {itemsForDay.map((item, index) => (
              <ListGroup.Item key={index}>
                {item.category}: {item.title}: ${item.amount}
              </ListGroup.Item>
            ))}
          </ListGroup>
        ) : (
          <p>No expenses for this day.</p>
        )}

        <Row className="mt-4">
          <PieChart items={itemsForDay} />
        </Row>
      </Card.Body>
    </Card>
  );
};

export default DayView;
