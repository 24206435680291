import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import uuid from "react-uuid";
import webappTrackingSVC from "./webappTrackingSVC";
const PathMonitor = () => {
  const location = useLocation();
  const [startTime, setStartTime] = useState(new Date());
  const [eventId] = useState(uuid());

  useEffect(() => {
    // const event = {
    //   eventId,
    //   createdDate: startTime.toISOString(),
    //   eventType: "Page",
    //   pathname: location.pathname,
    //   referrer: document.referrer,
    // };
    // if (webappTrackingSVC.socketIsOpen()) {
    //   webappTrackingSVC.track({ event });
    // } else {
    //   setTimeout(() => webappTrackingSVC.track({ event }), 1000);
    // }
    // setStartTime(new Date());
  }, [location]);

  return <div></div>;
};

export default PathMonitor;
