import { useContext, useState } from "react";
import { Table, Form } from "react-bootstrap";
import musicMetadataDB, { deleteAllMusic } from "../storage/musicMetadataDB";
import { Button } from "react-bootstrap";
import AppContext from "../context/app-context";
import audioStorage from "../storage/audioStorage";
import axios from "axios";
import { BsFillTrash3Fill } from "react-icons/bs";
import { AiFillPlayCircle } from "react-icons/ai";
import { toast } from "react-toastify";
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "../utils/helperFunctions";
import CustomTable from "../components/customTable";
import metadataDB from "../storage/metadataDB";

export default function OfflineSongLirary() {
  const {
    setLoading,
    downloadedSongs = [],
    setDownloadedSongs,
    fetchDownloadedSongs,
    playSongByIndex,
  } = useContext(AppContext);
  const [query, setQuery] = useState("");
  const [defaultRandomizeSong, setDefaultRandomizeSong] = useState(
    Boolean(getLocalStorageItem("defaultRandomizeSong"))
  );

  async function deleteSongFromLocal(id) {
    await audioStorage.deleteAudioBlob(id);
    await musicMetadataDB.songs.delete(id);
    fetchDownloadedSongs({ deleteSongs: true });
  }

  const playItem = (song) => {
    const { id, title } = song;
    const findIndex = downloadedSongs.findIndex((item) => item.id === id);
    playSongByIndex && playSongByIndex(findIndex);
    toast.info(`Playing ${title} now`);
  };

  return (
    <div style={{ margin: "0 0 20px 0" }}>
      <>
        <Form.Label>Search</Form.Label>
        <div style={{ display: "flex" }}>
          <Form.Control
            type="text"
            value={query}
            onChange={(e) => {
              const val = e.target.value;
              setQuery(val);
            }}
          />
        </div>
      </>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "10px 0",
          }}
        >
          <h2>Offline Song Library</h2>
          <Button
            onClick={async () => {
              const deleteCount = await deleteAllMusic();
              toast.info(`Successfully deleted ${deleteCount} songs.`);
            }}
          >
            Delete All
          </Button>
          <Button
            onClick={async () => {
              const deleteCount = await metadataDB.deleteAllCaches();
              toast.info(`Successfully deleted ${deleteCount} movies.`);
            }}
          >
            Delete All Movies
          </Button>
        </div>
        <div>
          <Form>
            <Form.Check
              inline
              label="Randomize your song list when you come back in"
              checked={defaultRandomizeSong}
              onChange={(e) => {
                setDefaultRandomizeSong(!defaultRandomizeSong);
                setLocalStorageItem(
                  "defaultRandomizeSong",
                  !defaultRandomizeSong
                );
              }}
              type={"checkbox"}
            />
          </Form>
        </div>
        <CustomTable
          headers={["Title", "Artist", "Delete"]}
          colCanSort={[true, true, false]}
          rows={downloadedSongs
            .filter((song) => {
              return JSON.stringify(song).includes(query);
            })
            .map((song, index) => {
              const { id } = song;
              return [
                { onClick: () => playItem(song), value: song.title },
                { onClick: () => playItem(song), value: song.artist },
                {
                  onClick: () => {
                    deleteSongFromLocal(id);
                  },
                  value: <BsFillTrash3Fill />,
                },
              ];
            })}
        />
      </div>
    </div>
  );
}
