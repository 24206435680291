import React, { useEffect, useRef, useState } from "react";
import ReactJkMusicPlayer from "react-jinke-music-player";
import "react-jinke-music-player/assets/index.css";
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "../utils/helperFunctions";
import audioStorage from "../storage/audioStorage";

export default function MusicPlayerList(props) {
  const { playList, passAudioInstanceToParent } = props;
  const musicPlayerRef = useRef(null);
  const [defaultVolume, setDefaultVolume] = useState(
    getLocalStorageItem("defaultVolume") || 0.5
  );
  const [defaultPlayMode] = useState(
    getLocalStorageItem("defaultPlayMode") || "orderLoop"
  );
  useEffect(() => {
    setLocalStorageItem("defaultVolume", defaultVolume);
  }, [defaultVolume]);
  return (
    <>
      <ReactJkMusicPlayer
        autoPlay={false}
        ref={musicPlayerRef}
        defaultPlayMode={defaultPlayMode}
        defaultVolume={defaultVolume}
        onAudioVolumeChange={(volume) => {
          const realVol = Math.pow(volume, 1 / 2);
          setDefaultVolume(realVol);
        }}
        onPlayModeChange={(mode) => {
          setLocalStorageItem("defaultPlayMode", mode);
        }}
        audioLists={playList.map((song) => {
          const { title, artist, src, id } = song;
          return {
            name: title,
            musicSrc: src,
            singer: artist,
            cover: "/asuna1.png",
            id,
          };
        })}
        showDownload={false}
        getAudioInstance={(instance) => {
          passAudioInstanceToParent(instance);
        }}
        // clearPriorAudioLists
        theme="auto"
        showLyric={true}
        drag={false}
        defaultPosition={{ top: "16%", right: "0" }}
        glassBg={true}
        showMiniProcessBar={true}
        showMediaSession={true}
        getAudioSrcById={async (id, obj) => {
          const audioBlob = await audioStorage.getAudioBlob(id);
          const audioUrl = URL.createObjectURL(audioBlob);
          return audioUrl;
        }}
      />
    </>
  );
}
