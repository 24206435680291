import Auth from "@aws-amplify/auth";
import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AppContext from "../context/app-context";
import useAuth from "../context/useAuth";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Card from "react-bootstrap/Card";
import LoginModel from "./loginModal";
function Header() {
  // const navigrate = useNavigate();
  const { currentUser, signOut } = useAuth();
  const navigate = useNavigate();
  const { userProfile, setAppRefresh } = useContext(AppContext);
  const currentPath = window.location.pathname + window.location.search;
  const isMobile = window.innerWidth < 700;
  return (
    <header>
      <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand onClick={() => navigate("/")}>
            <Card.Img
              variant="top"
              src={isMobile ? "favicon.ico" : "/apple-touch-icon.png"}
              width={isMobile ? undefined : "35px"}
              height={isMobile ? undefined : "35px"}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link onClick={() => navigate("/")}>Home</Nav.Link>
              <Nav.Link onClick={() => navigate("/downloaded-songs")}>Downloaded Songs</Nav.Link>
              <Nav.Link onClick={() => navigate("/online-songs")}>Online Songs</Nav.Link>
              <Nav.Link onClick={() => navigate("/ExpenseTracking")}>Expense</Nav.Link>
              <Nav.Link onClick={() => navigate("/movie")}>Movies</Nav.Link>
            </Nav>
            <Nav>
              {currentUser === null && <Nav.Link onClick={() => navigate(`/login?redirect=${currentPath}`)}>Login</Nav.Link>}
              {currentUser !== null && <Nav.Link onClick={() => navigate("/profile")}>Profile</Nav.Link>}
              {currentUser !== null && (
                <Nav.Link
                  onClick={() => {
                    if (currentUser !== null) {
                      Auth.signOut();
                      setAppRefresh(Math.random());
                    }
                  }}
                >
                  SignOut
                </Nav.Link>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {/* <LoginModel showModal={true} /> */}
    </header>
  );
}

export default Header;
