import { useContext, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import uuid from "react-uuid";

import { EditableModal } from "../components/editableModal";
import DateNavigator from "../components/ExpenseDateComp/DateNavigator";
import ExpenseList from "../components/ExpenseList";
import { CREATE_EXPENSE_CONFIGS } from "../configs/createNovelConfig";
import AppContext from "../context/app-context";
import ExpenseTrackingMetadataDB from "../storage/ExpenseTrackingMetadataDB";
import cryptoUtils from "../utils/cryptoUtils";
import { isValidNumber } from "../utils/helperFunctions";
import { INPUT_TYPE } from "../constants";
import userProfileService from "../services/userProfileService";
import ConfirmModal from "../components/ConfirmModal";

export function ExpenseTrackingPage(props) {
  const [tackingItems, setTrackingItems] = useState([]);
  const { userProfile, setLoading } = useContext(AppContext);
  const [query, setQuery] = useState("");
  const [showSaveShareBtn, setShowSaveShareBtn] = useState(false);
  const [canUpdateItems, setCanUpdateItems] = useState(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const shareStr = searchParams.get("share");

  async function loadItems() {
    // setLoading(true);
    const items = await ExpenseTrackingMetadataDB.getAllTrackingSpends();
    setTrackingItems(items);
    // setLoading(false);
    return items;
  }
  async function loadItemsFromOnline() {
    const { data, errorMsg } = await userProfileService.getExpenseEvent({});
    if (errorMsg) {
      toast.error(errorMsg);
    }
    let { expenseItems: { items: expenseItems = [], LastEvaluatedKey } = {} } = data;
    const offlineItems = await loadItems();

    // check if offline items
    const updatedItems = [];
    for (const offlineItem of offlineItems) {
      if (expenseItems.find((item) => item.id == offlineItem.id && item.lastUpdatedDate === offlineItem.lastUpdatedDate)) {
        break;
      } else {
        updatedItems.push(offlineItem);
      }
    }
    if (expenseItems.length > 0) {
      const firstItem = expenseItems[0];
      // if not up to date
      if (!offlineItems.find((item) => item.id == firstItem.id && item.lastUpdatedDate === firstItem.lastUpdatedDate)) {
        while (LastEvaluatedKey) {
          for (const onlineItem of expenseItems) {
            await ExpenseTrackingMetadataDB.putItemToExpenseTrackingMetadata(onlineItem);
          }
          const { data, errorMsg } = await userProfileService.getExpenseEvent({ LastEvaluatedKey });
          if (errorMsg) {
            toast.error(errorMsg);
          }
          expenseItems = data.expenseItems.items;
          LastEvaluatedKey = data.expenseItems.LastEvaluatedKey;
          console.log({ expenseItems, LastEvaluatedKey });
        }
        for (const onlineItem of expenseItems) {
          await ExpenseTrackingMetadataDB.putItemToExpenseTrackingMetadata(onlineItem);
        }
        loadItems();
      }
    }
    if (updatedItems && updatedItems.length > 0) setCanUpdateItems(updatedItems);
  }
  useEffect(() => {
    const decryObj = cryptoUtils.decryptToObject(shareStr);
    if (decryObj && decryObj.length > 0) {
      toast.info("Loading shared list.");
      setTrackingItems(decryObj);
      setShowSaveShareBtn(true);
    } else {
      loadItems();
    }
  }, []);

  useEffect(() => {
    if (userProfile) {
      loadItemsFromOnline();
    }
  }, [userProfile]);

  const filteredListItems = tackingItems.filter((song) => {
    return JSON.stringify(song).toLowerCase().includes(query.toLowerCase());
  });
  return (
    <div>
      <div style={{ margin: "10px 0", display: "flex", justifyContent: "space-around", flexWrap: "wrap" }}>
        {
          <EditableModal
            title={"create new expense"}
            configs={CREATE_EXPENSE_CONFIGS}
            actionLabel={"Create"}
            actionOnClick={async (data) => {
              const createdDate = new Date().toISOString();
              const { bulkAddItemStr, title, amount } = data;
              if (bulkAddItemStr && bulkAddItemStr.length > 0) {
                const items = bulkAddItemStr.split("\n");
                items.forEach((item) => {
                  const titleAmount = item.split(",");
                  if (titleAmount.length >= 2) {
                    const tit = titleAmount[0].trim();
                    const id = uuid();
                    const amt = titleAmount[1].trim();
                    let description = "";
                    if (titleAmount.length >= 3) {
                      description = titleAmount[2].trim();
                    }
                    if (!tit || !amt || !isValidNumber(amt)) {
                      toast.warn("Unable to add " + tit + " due to validation error.");
                    } else {
                      const item = {
                        ...data,
                        title: tit,
                        amount: amt,
                        description,
                        createdDate,
                        id,
                        lastUpdatedDate: createdDate,
                      };
                      ExpenseTrackingMetadataDB.putItemToExpenseTrackingMetadata(item);
                      setTrackingItems((prev) => [item, ...prev]);
                      toast.info(`Successfully added ${tit}`);
                    }
                  }
                });
              } else if (title && amount) {
                const id = uuid();
                const item = { ...data, createdDate, id, lastUpdatedDate: createdDate };
                delete item.bulkAddItemStr;
                setLoading(true);
                await ExpenseTrackingMetadataDB.putItemToExpenseTrackingMetadata(item);
                setTrackingItems((prev) => [item, ...prev]);
                setLoading(false);
                toast.info(`Successfully added ${title}`);
              }
            }}
          />
        }
        <Button
          onClick={() => {
            if (showSaveShareBtn) {
              // save all items
              setLoading(true);
              filteredListItems.forEach((item) => ExpenseTrackingMetadataDB.putItemToExpenseTrackingMetadata(item));
              // setSearchParams({
              //   share: "",
              // });
              setShowSaveShareBtn(false);
              loadItems();
            } else {
              const encryStr = cryptoUtils.encryptObjectToString(filteredListItems);
              // setSearchParams({
              //   share: encryStr,
              // });

              toast.info("Items are copied, you can now share this to whoever want to view this list.");
              navigator.clipboard.writeText(encryStr);
            }
          }}
        >
          {showSaveShareBtn ? "Save to my list" : "Share"}
        </Button>
        <EditableModal
          title={"Import Items"}
          configs={[
            {
              fieldName: "itemCode",
              label: "ItemCodes",
              inputType: INPUT_TYPE.TEXT_AREA,
              required: true,
            },
          ]}
          actionLabel={"Import"}
          actionOnClick={async ({ itemCode }) => {
            const decryObj = cryptoUtils.decryptToObject(itemCode);
            if (decryObj && decryObj.length > 0) {
              toast.info("Loading shared list.");
              setTrackingItems(decryObj);
              setShowSaveShareBtn(true);
            } else {
              toast.error("Unable to extract items from your code.");
            }
          }}
        />
      </div>
      <div>
        <ConfirmModal
          show={canUpdateItems !== null}
          title={"Do you want to upload your expense online?"}
          subtitle={"You have offline expenses, do you want save it to your account?"}
          onCancel={() => {
            setCanUpdateItems(null);
          }}
          onConfirm={async () => {
            setLoading(true);
            const { userId } = userProfile;
            for (const updatingItem of canUpdateItems.reverse()) {
              delete updatingItem.bulkAddItemStr;
              console.log(`Upserting item`, updatingItem);
              await userProfileService.putExpenseEvent({
                updatedItem: updatingItem,
              });
              await ExpenseTrackingMetadataDB.putItemToExpenseTrackingMetadata({ ...updatingItem, userId });
            }
            setLoading(false);
            setCanUpdateItems(null);
          }}
        />
      </div>
      <div>
        <Form.Label>Search</Form.Label>
        <div style={{ display: "flex" }}>
          <Form.Control
            type="text"
            value={query}
            onChange={(e) => {
              const val = e.target.value;
              setQuery(val);
            }}
          />
        </div>
      </div>
      <div>
        <ExpenseList items={filteredListItems} paginationSize={50} loadItems={loadItems} />
      </div>
      <div>
        <DateNavigator expensesItems={filteredListItems} />
      </div>
    </div>
  );
}
