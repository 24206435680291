// db.js
import Dexie from "dexie";
import audioStorage from "./audioStorage";

const musicMetadataDB = new Dexie("musicMetadataDB");

musicMetadataDB.version(1).stores({
  songs: "&id, title, artist, album, dwonloadedDate",
});

export async function deleteAllMusic() {
  const downloadedSongs = (await musicMetadataDB.songs.toArray()) || [];
  for (var i = 0; i < downloadedSongs.length; i++) {
    const { id } = downloadedSongs[i];
    await audioStorage.deleteAudioBlob(id);
    await musicMetadataDB.songs.delete(id);
  }
  return downloadedSongs.length;
}

export default musicMetadataDB;
