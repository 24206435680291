// db.js
import Dexie from "dexie";

// Create database
const MyCarDB = new Dexie("MyCarDB");

// Define version and stores
MyCarDB.version(1).stores({
  carItems:
    "&id, carMake, carModel, carYear, carMileage, carVIN, carLicensePlate, carCategory, createdDate, lastUpdatedDate",
  carEvents: "&id, eventType, eventDate, carId, notes, mileage",
});

// CRUD operations for car stats

// Add or update car item
async function putCarItem(item) {
  const lastUpdatedDate = new Date().toISOString();
  console.log("putting car item", item);
  return MyCarDB.carItems.put({ ...item, lastUpdatedDate });
}

// Get all car items
export async function getAllCarItems() {
  const items = await MyCarDB.carItems.toArray();
  return items;
}

// Delete car item by ID
export async function deleteCarItem(id) {
  return MyCarDB.carItems.delete(id);
}

// CRUD operations for car events

// Add or update car event
async function putCarEvent(event) {
  const lastUpdatedDate = new Date().toISOString();
  return MyCarDB.carEvents.put({ ...event, lastUpdatedDate });
}

// Get all events for a specific car
export async function getEventsByCarId(carId) {
  return MyCarDB.carEvents.where("carId").equals(carId).toArray();
}

// Delete event by ID
export async function deleteCarEvent(id) {
  return MyCarDB.carEvents.delete(id);
}

export default {
  putCarItem,
  getAllCarItems,
  deleteCarItem,
  putCarEvent,
  getEventsByCarId,
  deleteCarEvent,
};
