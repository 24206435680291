import { INPUT_TYPE } from "../../constants";
import { EditableModal } from "../editableModal";

export default function AddNewCar(props) {
  const { onAdd = () => {} } = props;

  return (
    <div>
      <EditableModal
        title={"Add a New Car"}
        configs={[
          {
            fieldName: "carMake",
            label: "Car Make",
            inputType: INPUT_TYPE.TEXT,
            required: true,
          },
          {
            fieldName: "carModel",
            label: "Car Model",
            inputType: INPUT_TYPE.TEXT,
            required: true,
          },
          {
            fieldName: "carYear",
            label: "Year of Manufacture",
            inputType: INPUT_TYPE.NUMBER,
            required: true,
          },
          {
            fieldName: "carMileage",
            label: "Mileage (in miles)",
            inputType: INPUT_TYPE.NUMBER,
          },
          {
            fieldName: "carVIN",
            label: "Vehicle Identification Number (VIN)",
            inputType: INPUT_TYPE.TEXT,
            required: false,
          },
          {
            fieldName: "carLicensePlate",
            label: "License Plate",
            inputType: INPUT_TYPE.TEXT,
          },
          {
            fieldName: "carCategory",
            label: "Category",
            enumValues: [
              "SUV",
              "Sedan",
              "Truck",
              "Coupe",
              "Hatchback",
              "Convertible",
              "Van",
              "Electric",
              "Hybrid",
              "Other",
            ].map((item) => ({
              name: item,
              value: item,
            })),
            inputType: INPUT_TYPE.SELECT,
          },
        ]}
        actionLabel={"Create"}
        actionOnClick={async (data) => {
          onAdd(data);
        }}
      />
    </div>
  );
}
