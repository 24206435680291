import React, { Component, useEffect, useRef, useState } from "react";
import { ControlBar, Player } from "video-react";
import "video-react/dist/video-react.css";

export default function VideoPlayer(props) {
  const { source, playNext } = props;
  const playerRef = useRef(null);
  useEffect(() => {
    if (source && playerRef && playerRef.current) {
      playerRef.current.video.handleEnded = (res) => {
        playNext && playNext();
      };
    }
  }, [source]);
  if (source) {
    return (
      <div style={{ width: "80%" }}>
        <Player
          playsInline
          // poster='/assets/poster.png'
          ref={playerRef}
          autoPlay={true}
          fluid={true}
          src={source}
        >
          <ControlBar autoHide={false} />
        </Player>
      </div>
    );
  } else {
    return <div>No video selected</div>;
  }
}
