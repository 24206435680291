import React, { useState } from "react";
import { EditableModal } from "../components/editableModal";
import { CREATE_MOVIE_CONFIGS } from "../configs/createNovelConfig";
import movieService from "../services/movieService";
// import collectionService from "../services/collectionService";

export default function AdminPage(props) {
  const [createdItems, setcreatedItems] = useState([]);
  return (
    <div>
      {
        <EditableModal
          title={"create new movie"}
          configs={CREATE_MOVIE_CONFIGS}
          actionLabel={"Create"}
          actionOnClick={(data) => {
            movieService.createMovieMetadata({
              item: data,
              callback: ({ data }) => {
                console.log(data);
                setcreatedItems((prev) => [...prev, data]);
              },
            });
          }}
        />
      }
      <div>
        {createdItems.map((item) => {
          return <div key={item.movieId}> {JSON.stringify(item)} </div>;
        })}
      </div>
    </div>
  );
}
