import "./css/App.css";
import "./css/reactSearchStyle.css";
// import "react-select-search/style.css";
import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { PacmanLoader } from "react-spinners";
import { ToastContainer } from "react-toastify";

import AuthenticateWrapper from "./components/authenticateWrapper";
import Header from "./components/header";
import Login from "./components/Login";
import MusicPlayerList from "./components/musicPlayerList";
import AppContext from "./context/app-context";
import AdminPage from "./page/adminPage";
import HomePage from "./page/homePage";
import OfflineSongLirary from "./page/offlineSongLibrary";
import SongHomePage from "./page/songHomePage";
import { UploadSongPage } from "./page/uploadSongPage";
import userProfileService from "./services/userProfileService";
import WebStats from "./services/webStats";
import audioStorage from "./storage/audioStorage";
import musicMetadataDB, { deleteAllMusic } from "./storage/musicMetadataDB";
import { dubugTest, getLocalStorageItem, setLocalStorageItem } from "./utils/helperFunctions";
import MovieListPage from "./page/movieListPage";
import MovieDetailPage from "./page/movieDetailPage";
import RedirectWithCountdown from "./widgets/redirect";
import metadataDB from "./storage/metadataDB";
import TripCostSplitterrPage from "./page/tripCostSplitterPage";
import { ExpenseTrackingMetadataDB, ExpenseTrackingPage } from "./page/ExpenseTrackingPage";
import { CarsTrackingPage } from "./page/CarTrackingPage";

export const APP_ROUTE_LIST = [
  {
    path: "/",
    element: <HomePage />, // <AuthenticateWrapper components={<TicTacToe />} />,
  },
  {
    path: "/create",
    element: <AdminPage />,
  },
  {
    path: "/uploadSong",
    element: <UploadSongPage />,
    title: "Upload a song to the system",
    description: " ",
  },
  {
    path: "/online-songs",
    element: <SongHomePage />,
    title: "Online Song collection",
    description: "Download songs and play it offline",
  },
  {
    path: "/downloaded-songs",
    element: <OfflineSongLirary />,
    title: "Downloaded Song",
    description: "Download songs and play it offline",
  },
  {
    path: "/login",
    element: <AuthenticateWrapper components={<Login />} />,
  },
  {
    path: "/movie",
    title: "Watch movies",
    description: "Download movies and watch them offline",
    element: <MovieListPage />,
  },
  {
    path: "/movie/:movieId",
    element: <MovieDetailPage />,
  },
  {
    path: "/trip-split",
    element: <TripCostSplitterrPage />,
    title: "Trip Cost Splitterr Page",
    description: "Split the cost for your trip, your must sign up to use this.",
  },
  {
    path: "/ExpenseTracking",
    element: <ExpenseTrackingPage />,
    title: "ExpenseTrackingPage",
    description: "ExpenseTrackingPage.",
  },
  {
    path: "/myCars",
    element: <CarsTrackingPage />,
    title: "Car Stats Tracking",
    description: "Track your cars stats like mile, tire rotation event.",
  },
  // {
  //   path: "/profile",
  //   element: <AuthenticateWrapper components={<ProfilePage />} />,
  // },
];
function App() {
  const [loading, setLoading] = useState(false);
  const [appRefresh, setAppRefresh] = useState(0);
  const [userProfile, setUserProfile] = useState(null);
  const [downloadedSongs, setDownloadedSongs] = useState([]);
  const [playingSongs, setPlayingSongs] = useState([]);
  const [audioInstance, setAudioInstance] = useState(null);
  useEffect(() => {
    userProfileService.getUserProfile({
      callback: ({ userProfile: profile }) => {
        setUserProfile(profile);
        const { userId } = profile || {};
        setLocalStorageItem("userId", userId);
      },
    });
  }, [appRefresh]);
  const fetchDownloadedSongs = async ({ deleteSongs = false } = {}) => {
    const songs = await musicMetadataDB.songs.toArray();
    const songWithAudio = await Promise.all(
      songs.map(async (song) => {
        const { id } = song;
        // const audioBlob = await audioStorage.getAudioBlob(id);
        // const audioUrl = URL.createObjectURL(audioBlob);
        return {
          ...song,
          // src: audioUrl,
        };
      })
    );
    // if (songWithAudio && songWithAudio.length > 0) {
    //   const { id } = songWithAudio[0];
    //   const audioBlob = await audioStorage.getAudioBlob(id);

    //   const audioUrl = URL.createObjectURL(audioBlob);
    //   songWithAudio[0] = { ...songWithAudio, src: audioUrl };
    // }
    setDownloadedSongs(
      songWithAudio.sort((i1, i2) => {
        return new Date(i2.downloadedDate).getTime() - new Date(i1.downloadedDate).getTime();
      })
    );
    if (!deleteSongs) {
      if (Boolean(getLocalStorageItem("defaultRandomizeSong"))) {
        setPlayingSongs(songWithAudio.sort(() => (Math.random() > 0.5 ? 1 : -1)));
      } else {
        setPlayingSongs(songWithAudio);
      }
    }
  };

  useEffect(dubugTest, []);

  useEffect(() => {
    fetchDownloadedSongs();
  }, []);
  // useEffect(() => {}, [userProfile]);
  const playSongByIndex = (index) => {
    if (audioInstance != null) {
      audioInstance.playByIndex(index);
    }
  };

  if (window.location.href.includes("digital")) {
    metadataDB.deleteAllCaches();
    deleteAllMusic();
    return <RedirectWithCountdown />;
  }

  return (
    <div className="App">
      <BrowserRouter>
        <AppContext.Provider
          value={{
            loading,
            setLoading,
            userProfile,
            setAppRefresh,
            downloadedSongs,
            setDownloadedSongs,
            setPlayingSongs,
            fetchDownloadedSongs,
            playSongByIndex,
          }}
        >
          <>
            {loading && (
              <div className="fadeMe">
                <PacmanLoader color="#36d7b7" loading={loading} className={"spinner"} />
              </div>
            )}
            <Header />
            <WebStats />
            <div>
              <MusicPlayerList
                playList={playingSongs}
                passAudioInstanceToParent={(instance) => {
                  setAudioInstance(instance);
                }}
              />
            </div>
            <div className="container">
              {/* <Spinner loading={loading} />{" "} */}
              <Routes>
                {APP_ROUTE_LIST.map((item) => {
                  return <Route key={item.path} path={item.path} element={item.element} />;
                })}
              </Routes>
            </div>
          </>
        </AppContext.Provider>
      </BrowserRouter>
      <ToastContainer />
    </div>
  );
}

export default App;
