import React, { useState, useEffect } from "react";
import { Button, Card, Collapse } from "react-bootstrap";
import CustomTable from "../customTable";
import CarMetadataDB from "../../storage/CarMetadataDB"; // Your Dexie DB for car data
import AddCarEvent from "./AddCarEvent";
import uuid from "react-uuid";
import userProfileService from "../../services/userProfileService";

export default function CarDetails(props) {
  const { carDetails = {}, onUpdateCarAtt } = props;
  const { id, carMake, carModel, carYear, carMileage, carVIN, carLicensePlate, carCategory } = carDetails;

  const [open, setOpen] = useState(true);
  const [carEvents, setCarEvents] = useState([]);

  // Fetch car events when the component loads
  useEffect(() => {
    async function fetchCarEvents() {
      const events = await CarMetadataDB.getEventsByCarId(id);
      setCarEvents(events);
    }

    if (id) {
      fetchCarEvents();
    }
  }, [id]);

  // Handle adding new event
  const handleAddEvent = async (newEvent) => {
    // Add carId to the event before saving
    const eventId = uuid();
    const createdDate = new Date().toISOString();
    const eventWithCarId = { ...newEvent, carId: id, id: eventId, createdDate };
    await CarMetadataDB.putCarEvent(eventWithCarId);
    await userProfileService.putCarEvent({ updatedItem: eventWithCarId });
    const { carMileage } = eventWithCarId;
    if (carMileage) {
      // update card
      onUpdateCarAtt({ carMileage });
    }

    // Refresh car events after adding the new one
    const updatedEvents = await CarMetadataDB.getEventsByCarId(id);
    setCarEvents(updatedEvents);
  };

  return (
    <div style={{ marginTop: "10px" }}>
      <Card>
        <Card.Body>
          <h3>Car Details</h3>
          <p>
            <strong>Make:</strong> {carMake}
          </p>
          <p>
            <strong>Model:</strong> {carModel}
          </p>
          <p>
            <strong>Year:</strong> {carYear}
          </p>
          <p>
            <strong>Mileage:</strong> {carMileage} miles
          </p>
          <p>
            <strong>VIN:</strong> {carVIN}
          </p>
          <p>
            <strong>License Plate:</strong> {carLicensePlate}
          </p>
          <p>
            <strong>Category:</strong> {carCategory}
          </p>

          {/* AddCarEvent component */}
          <AddCarEvent onAddEvent={handleAddEvent} />

          {/* Toggle for showing car events log */}
          {/* <Button onClick={() => setOpen(!open)} aria-controls="car-events-collapse" aria-expanded={open} variant="primary">
            {open ? "Hide Car Events Log" : "Show Car Events Log"}
          </Button> */}

          {/* Car Events Log */}
          <Collapse in={open}>
            <div id="car-events-collapse" style={{ marginTop: "10px" }}>
              <h4>Car Events Log</h4>
              {carEvents.length > 0 ? (
                <CustomTable
                  data={carEvents}
                  headers={["Event Type", "Date", "Mileage (in miles)", "eventCost", "Notes"]}
                  colCanSort={[true, true, true, true]}
                  paginationSize={30}
                  rows={carEvents.map((item) => {
                    return [
                      { value: item.eventType },
                      { value: item.eventDate },
                      { value: item.carMileage },
                      { value: item.eventCost ? "$" + item.eventCost : "" },
                      { value: item.notes },
                    ];
                  })}
                />
              ) : (
                <p>No events logged for this car.</p>
              )}
            </div>
          </Collapse>
        </Card.Body>
      </Card>
    </div>
  );
}
