import { Auth } from "aws-amplify";
import axios from "axios";
import { toast } from "react-toastify";
import { isUserLogined } from "../context/useAuth";
import { getUserJwtToken } from "../utils/helperFunctions";

const SVC_URL = "https://user.service.m.crz97.com/";

const client = axios.create({
  baseURL: SVC_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "x-api-key": "UserProfileServiceasklfhaskjfhaslkjhjkwoj",
  },
});

const API_PATH = {
  profile: "/profile",
  tripsplit: "/tripsplit",
  expense: "/expense",
  cardetails: "/car-details",
  carevent: "/car-event",
};

async function getUserProfile({ callback }) {
  if (!(await isUserLogined())) {
    callback({});
    return;
  }
  const user = await Auth.currentAuthenticatedUser();

  const jwtToken = getUserJwtToken(user);
  client
    .get(`${API_PATH.profile}`, {
      headers: {
        Authorization: jwtToken,
      },
    })
    .then((res) => {
      //   console.log("Success:", res);
      const {
        data: { userProfile = {} },
      } = res;
      callback({ userProfile });
    })
    .catch((error) => {
      console.error("Error:", error);
      callback({ error });
    });
}

async function updateUserProfile({ displayName, userId, callback }) {
  if (!(await isUserLogined())) {
    alert("You need to login first!!");
    return;
  }
  const user = await Auth.currentAuthenticatedUser();
  const jwtToken = getUserJwtToken(user);
  client
    .put(
      `${API_PATH.profile}`,
      {
        displayName,
        userId,
      },
      {
        headers: {
          Authorization: jwtToken,
        },
      }
    )
    .then((res) => {
      const {
        data: { userProfile = {} },
        data,
      } = res;
      callback({ userProfile });
    })
    .catch((error) => {
      console.error("Error:", error);
      callback({ error });
    });
}

async function updateTripCost({ item, callback }) {
  if (!(await isUserLogined())) {
    alert("You need to login first!!");
    callback({});
    return;
  }
  const user = await Auth.currentAuthenticatedUser();
  const jwtToken = getUserJwtToken(user);
  client
    .post(
      `${API_PATH.tripsplit}`,
      {
        ...item,
      },
      {
        headers: {
          Authorization: jwtToken,
        },
      }
    )
    .then((res) => {
      //   console.log("Success:", res);
      const {
        data: { records = [] },
        data,
      } = res;
      console.log(data);
      callback({ records });
    })
    .catch((error) => {
      console.error("Error:", error);
      const {
        response: { data, status },
      } = error;
      if (status === 400) {
        alert(
          `Sorry, you don't have permission to update this record. feel free to create your own by visiting the home page!`
        );
      }
      callback({ error });
    });
}

async function getAllTripCost({ callback }) {
  if (!(await isUserLogined())) {
    callback({});
    return;
  }
  const user = await Auth.currentAuthenticatedUser();
  const jwtToken = getUserJwtToken(user);
  client
    .put(
      `${API_PATH.tripsplit}`,
      {},
      {
        headers: {
          Authorization: jwtToken,
        },
      }
    )
    .then((res) => {
      //   console.log("Success:", res);
      const {
        data: { records = [] },
        data,
      } = res;
      // console.log(data);
      callback({ records });
    })
    .catch((error) => {
      console.error("Error:", error);
      callback({ error });
    });
}

async function getTripCostById({ id, callback }) {
  // if (!(await isUserLogined())) {
  //   alert("You need to login first!!");
  //   return;
  // }
  // const user = await Auth.currentAuthenticatedUser();
  // const jwtToken = getUserJwtToken(user);
  client
    .get(`${API_PATH.tripsplit}?id=${id}`, {
      headers: {
        // Authorization: jwtToken,
      },
    })
    .then((res) => {
      //   console.log("Success:", res);
      const {
        data: { records = [] },
        data,
      } = res;
      // console.log(data);
      callback({ records });
    })
    .catch((error) => {
      console.error("Error:", error);
      callback({ error });
    });
}

async function putExpenseEvent({ updatedItem }) {
  if (!(await isUserLogined())) {
    alert("You need to login first!!");
    return;
  }
  const user = await Auth.currentAuthenticatedUser();
  const jwtToken = getUserJwtToken(user);
  return client
    .put(`${API_PATH.expense}`, updatedItem, {
      headers: {
        Authorization: jwtToken,
      },
    })
    .then((res) => {
      const { data } = res;
      return { data };
    })
    .catch((error) => {
      console.error("Error:", error);
      var errorMsg = error;
      if (error.respons?.data) {
        errorMsg = error.response.data;
      }
      return { error, errorMsg };
    });
}

async function getExpenseEvent({ LastEvaluatedKey } = {}) {
  const user = await Auth.currentAuthenticatedUser();
  const jwtToken = getUserJwtToken(user);
  return client
    .post(
      `${API_PATH.expense}`,
      {
        LastEvaluatedKey,
      },
      {
        headers: {
          Authorization: jwtToken,
        },
      }
    )
    .then((res) => {
      const { data } = res;
      return { data };
    })
    .catch((error) => {
      console.error("Error:", error);
      console.error("Error message", error.response.data);
      var errorMsg = error;
      if (error.respons?.data) {
        errorMsg = error.response.data;
      }
      return { error, errorMsg };
    });
}

async function getCarDetails({ LastEvaluatedKey } = {}) {
  const user = await Auth.currentAuthenticatedUser();
  const jwtToken = getUserJwtToken(user);
  return client
    .post(
      `${API_PATH.cardetails}`,
      {
        LastEvaluatedKey,
      },
      {
        headers: {
          Authorization: jwtToken,
        },
      }
    )
    .then((res) => {
      const { data } = res;
      return { data };
    })
    .catch((error) => {
      console.error("Error:", error);
      console.error("Error message", error.response.data);
      var errorMsg = error;
      if (error.respons?.data) {
        errorMsg = error.response.data;
      }
      return { error, errorMsg };
    });
}

async function putCarDetails({ updatedItem }) {
  if (!(await isUserLogined())) {
    // alert("You need to login first!!");
    return;
  }
  const user = await Auth.currentAuthenticatedUser();
  const jwtToken = getUserJwtToken(user);
  const { id } = updatedItem;
  delete updatedItem.id;
  return client
    .put(
      `${API_PATH.cardetails}`,
      { ...updatedItem, carId: id },
      {
        headers: {
          Authorization: jwtToken,
        },
      }
    )
    .then((res) => {
      const { data } = res;
      return { data };
    })
    .catch((error) => {
      console.error("Error:", error);
      var errorMsg = error;
      if (error.respons?.data) {
        errorMsg = error.response.data;
      }
      return { error, errorMsg };
    });
}

async function putCarEvent({ updatedItem }) {
  if (!(await isUserLogined())) {
    // alert("You need to login first!!");
    return;
  }
  const user = await Auth.currentAuthenticatedUser();
  const jwtToken = getUserJwtToken(user);
  const { id } = updatedItem;
  delete updatedItem.id;
  return client
    .put(
      `${API_PATH.carevent}`,
      { ...updatedItem, carId: id },
      {
        headers: {
          Authorization: jwtToken,
        },
      }
    )
    .then((res) => {
      const { data } = res;
      return { data };
    })
    .catch((error) => {
      console.error("Error:", error);
      var errorMsg = error;
      if (error.respons?.data) {
        errorMsg = error.response.data;
      }
      return { error, errorMsg };
    });
}

export default {
  getUserProfile,
  updateTripCost,
  updateUserProfile,
  getTripCostById,
  getAllTripCost,
  putExpenseEvent,
  getExpenseEvent,
  putCarDetails,
  putCarEvent,
  getCarDetails,
};
