// db.js
import Dexie from "dexie";

const ExpenseTrackingMetadataDB = new Dexie("ExpenseTrackingMetadataDB");

ExpenseTrackingMetadataDB.version(1).stores({
  items: "&id, title, description, amount, category, createdDate, lastUpdatedDate, purchasedDate",
});

async function putItemToExpenseTrackingMetadata(item) {
  return ExpenseTrackingMetadataDB.items.put({ ...item });
}

export async function getAllTrackingSpends() {
  const items = (await ExpenseTrackingMetadataDB.items.toArray()) || [];
  return items.sort((a, b) => new Date(b.lastUpdatedDate) - new Date(a.lastUpdatedDate));
}

export default { getAllTrackingSpends, putItemToExpenseTrackingMetadata };
