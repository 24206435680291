import { toast } from "react-toastify";

export const getUserJwtToken = (user) => {
  if (!user) return null;
  return user.getSignInUserSession().getIdToken().getJwtToken();
};

export function setLocalStorageItem(key, item) {
  localStorage.setItem(key, JSON.stringify(item));
}

export function getLocalStorageItem(key) {
  const item = localStorage.getItem(key);
  if (item) {
    try {
      return JSON.parse(item);
    } catch (err) {
      console.log(err.message);
    }
  }
  return null;
}

export function generateRandomId() {
  return Math.random().toString(36).slice(2);
}

export function getTimeSinceDate(date) {
  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years ago";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months ago";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days ago";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours ago";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes ago";
  }
  return Math.floor(seconds) + " seconds ago";
}

export function get2DigitNumber(sum) {
  return Math.round(sum * 100) / 100;
}

export const dubugTest = () => {
  // const intervalId = setInterval(() => {
  //   debugger;
  // }, 10);
  // return () => {
  //   clearInterval(intervalId);
  // };
};

export function handleAPICallError({ callback = () => {}, err = {} }) {
  console.error("Error:", err);
  const { code, message, response: { data } = {} } = err;
  var returnErr = data;
  if (typeof data == "object") {
    if (data.message) returnErr = data.message;
    else returnErr = JSON.stringify(data);
  }
  if (!returnErr) {
    returnErr = `${code}: ${message}`;
  }
  toast.error(returnErr);
  callback({ err: returnErr });
}

export function isValidEmail(email) {
  // Regular expression to check the email format
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export function isValidNumber(value) {
  return !Number.isNaN(Number(value));
}
