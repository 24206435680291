import { useContext, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Select from "react-dropdown-select";
import { toast } from "react-toastify";
import { Table } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import userProfileService from "../services/userProfileService";
import { useEffect } from "react";
import TripCostStats from "../components/tripCostStats";
import AppContext from "../context/app-context";
import { BsFillTrash3Fill } from "react-icons/bs";
import {
  generateRandomId,
  get2DigitNumber,
  getTimeSinceDate,
} from "../utils/helperFunctions";
import jsPDF from "jspdf";
import "jspdf-autotable";

const generatePdf = ({
  headers = [],
  datas = [],
  title = "example",
  subtitles = [],
}) => {
  const doc = new jsPDF();

  console.log("Generating PDF for " + title, datas);
  const xAlight = 15;
  doc.setFontSize(30);
  doc.text(title, xAlight, 15);

  // Create table header
  var tableYPos = 30;
  for (var i = 0; i < headers.length; i++) {
    const header = [headers[i]];

    // Create table data
    const data = datas[i];
    const subtitle = subtitles[i];

    // Set table position and style
    const tableWidth = 180;
    const tableHeight = 10;
    const tableXPos = (doc.internal.pageSize.width - tableWidth) / 2;

    const tableStyle = {
      lineWidth: 0.1,
      lineColor: [0, 0, 0],
      fillColor: [255, 255, 255],
      textColor: [0, 0, 0],
      fontStyle: "normal",
    };
    doc.setFontSize(20);
    doc.text(subtitle, xAlight, tableYPos - 3);

    // Add table to PDF document
    doc.autoTable({
      head: header,
      body: data,
      theme: "striped",
      startY: tableYPos,
      margin: { top: tableYPos, left: tableXPos },
      tableWidth,
      tableHeight,
      styles: tableStyle,
    });
    tableYPos = tableYPos + data.length * tableHeight;
  }
  // Add footer to PDF document
  const footer = "Generated by Digital Space Link - Click here to learn more";
  const pageCount = doc.internal.getNumberOfPages();
  for (let i = 1; i <= pageCount; i++) {
    doc.setPage(i);
    doc.setFontSize(10);
    doc.textWithLink(
      footer,
      doc.internal.pageSize.width - footer.length * 2 - 10,
      doc.internal.pageSize.height - 5,
      {
        url: "https://digitalspacelink.com/trip-split",
      }
    );
  }
  // Save PDF document
  doc.save(`${title}-report.pdf`);
};

export default function TripCostSplitterrPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [record, setRecord] = useState({});
  const { setLoading, userProfile } = useContext(AppContext);
  const navigate = useNavigate();
  const [people, setPeople] = useState([]);
  const [enterName, setEnterName] = useState("");
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [price, setPrice] = useState("");
  const [payer, setPayer] = useState([]);
  const [listOfPayer, setListOfPayer] = useState([]);
  const [listOfItem, setListOfItem] = useState([]);
  const [historyRecords, setHistoryRecords] = useState([]);
  const id = searchParams.get("id");
  useEffect(() => {
    if (id) {
      setLoading(true);
      userProfileService.getTripCostById({
        id,
        callback: ({ records }) => {
          if (records) {
            setRecord(records[0]);
            const {
              people: recPeop,
              listOfItem: recList,
              title = "",
            } = records[0];
            setPeople(recPeop);
            setListOfItem(recList);
            setTitle(title);
          }
          setLoading(false);
        },
      });
    } else {
      setPeople([]);
      setListOfItem([]);
    }
    userProfileService.getAllTripCost({
      callback: ({ records }) => {
        if (records) {
          setHistoryRecords(records);
        }
      },
    });
  }, [id]);

  function updateBackEnd(pel, list) {
    setLoading(true);
    userProfileService.updateTripCost({
      item: {
        id,
        listOfItem: list,
        people: pel,
        title,
      },
      callback: ({ records }) => {
        if (records) {
          const { id } = records[0];
          setSearchParams({ id });
          setPeople(pel);
          setListOfItem(list);
        }
        setLoading(false);
      },
    });
  }

  function getPeopleSelectOptions() {
    return people.map((peo) => ({
      value: peo,
      label: peo,
    }));
  }
  const total = listOfItem.reduce((accumulator, currentItem) => {
    const { price } = currentItem;
    return get2DigitNumber(Number(accumulator) + Number(price));
  }, 0);
  const totalEachPaid = {};
  const totalEachShouldPaid = {};
  people.forEach((it) => {
    totalEachPaid[it] = 0;
    totalEachShouldPaid[it] = 0;
  });
  listOfItem.forEach((item) => {
    const {
      payer: [{ value }],
      listOfPayer = [],
      price,
    } = item;
    totalEachPaid[value] = totalEachPaid[value] + Number(price);
    const eachPay = get2DigitNumber(Number(price) / listOfPayer.length);
    listOfPayer.forEach((itemPayer) => {
      const { value: itemVal } = itemPayer;
      totalEachShouldPaid[itemVal] = get2DigitNumber(
        totalEachShouldPaid[itemVal] + eachPay
      );
    });
  });

  return (
    <div>
      <div style={{ display: "flex" }}>
        <div
          style={{ margin: "10px", border: "3px solid green", padding: "10px" }}
        >
          <div>
            <h3>Members</h3>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                if (!enterName) {
                  toast.error("Please enter a name");
                  return;
                }
                const newPeo = [...people, enterName];
                setEnterName("");
                updateBackEnd(newPeo, listOfItem);
              }}
            >
              <Form.Group className="mb-3">
                <Form.Label>Add an member's name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter name"
                  value={enterName}
                  onChange={(e) => setEnterName(e.target.value)}
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                Add
              </Button>
            </Form>
            {people.map((mem) => {
              return <div key={generateRandomId()}>{mem}</div>;
            })}
          </div>
          <div style={{ margin: "10px 0 " }}>
            <h3>History</h3>
            {historyRecords.map((hisRec) => {
              const { id, createdDate, title: recTitle = "" } = hisRec;
              return (
                <div
                  key={id}
                  onClick={() => navigate(`/trip-split?id=${id}`)}
                  style={{
                    border: "1px solid orange",
                    textAlign: "center",
                    cursor: "pointer",
                    margin: "5px 0",
                    maxWidth: "200px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <div>{`${recTitle} ${getTimeSinceDate(
                    new Date(createdDate)
                  )}`}</div>
                </div>
              );
            })}
            <div style={{ margin: "12px 0" }}>
              <Button variant="link" onClick={() => navigate(`/trip-split`)}>
                Create a new one
              </Button>
            </div>
          </div>
        </div>
        <div
          style={{ margin: "10px", border: "3px solid blue", padding: "10px" }}
        >
          <div>
            <Form.Group className="mb-3">
              <Form.Label>Title of the Trip</Form.Label>
              <Form.Control
                type="text"
                placeholder="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Form.Group>
          </div>
          <h3>Enter the cost</h3>
          <div>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                if (!description) {
                  toast.error("Please enter an description");
                  return;
                }
                if (!price) {
                  toast.error("Please enter an price");
                  return;
                }
                if (!payer || payer.length === 0) {
                  toast.error("Please enter an payer");
                  return;
                }
                if (!listOfPayer || listOfPayer.length === 0) {
                  toast.error("Please enter an listOfPayer");
                  return;
                }
                const newListItem = [
                  ...listOfItem,
                  { description, price, payer, listOfPayer },
                ];
                setListOfItem(newListItem);
                updateBackEnd(people, newListItem);
                setDescription("");
                setPrice("");
                setPayer([]);
                setListOfPayer([]);
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <div style={{ margin: "0 5px" }}>
                  <Form.Group className="mb-3">
                    <Form.Label>Short description</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Short description"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </Form.Group>
                </div>
                <div style={{ margin: "0 5px" }}>
                  <Form.Group className="mb-3">
                    <Form.Label>Enter the price of this item</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Enter the price of this item"
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                    />
                  </Form.Group>
                </div>
                <div style={{ margin: "0 5px" }}>
                  <Form.Label>who paid this item</Form.Label>
                  <Select
                    multi={false}
                    options={people.map((peo) => ({ value: peo, label: peo }))}
                    onChange={(values) => {
                      setPayer(values);
                      const fit = listOfPayer.filter(
                        (val) => val.value === values[0].value
                      );
                      if (fit.length === 0) {
                        setListOfPayer([...listOfPayer, values[0]]);
                      }
                    }}
                    values={payer}
                  />
                </div>
                <div style={{ margin: "0 5px" }}>
                  <Form.Label>Select who involved with this item</Form.Label>
                  <div style={{ display: "flex" }}>
                    <Select
                      multi={true}
                      options={getPeopleSelectOptions()}
                      values={listOfPayer}
                      onChange={(values) => setListOfPayer(values)}
                    />
                    <Button
                      onClick={() => setListOfPayer(getPeopleSelectOptions())}
                    >
                      Add all
                    </Button>
                  </div>
                </div>
              </div>
              <Button variant="primary" type="submit">
                Add item
              </Button>
            </Form>
          </div>
          <div>
            <Table responsive borderless striped hover size="sm">
              <thead>
                <tr>
                  <th>description</th>
                  <th>price</th>
                  <th>payer</th>
                  <th>list of payer</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {listOfItem.map((item, index) => {
                  const {
                    description: itemDesc,
                    price: itemPrice,
                    payer: itemPayer,
                    listOfPayer: itemListOfPayer,
                  } = item;
                  return (
                    <tr onClick={(e) => {}} key={generateRandomId()}>
                      <td>{itemDesc}</td>
                      <td>${itemPrice}</td>
                      <td>
                        <Select
                          multi={false}
                          options={getPeopleSelectOptions()}
                          onChange={(values) => {
                            listOfItem[index] = { ...item, payer: values };
                            updateBackEnd(people, listOfItem);
                          }}
                          values={itemPayer}
                        />
                      </td>
                      <td>
                        <Select
                          multi={true}
                          options={getPeopleSelectOptions()}
                          values={itemListOfPayer}
                          onChange={(values) => {
                            listOfItem[index] = {
                              ...item,
                              listOfPayer: values,
                            };
                            updateBackEnd(people, listOfItem);
                          }}
                        />
                      </td>
                      <td
                        onClick={() => {
                          var newList = listOfItem.filter(
                            (fiItem) =>
                              itemDesc !== fiItem.description &&
                              itemPrice !== fiItem.price
                          );
                          updateBackEnd(people, newList);
                        }}
                      >
                        <BsFillTrash3Fill />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
          <div
            className="costPageActions"
            style={{ display: "flex", justifyContent: "space-around" }}
          >
            <Button onClick={() => updateBackEnd(people, listOfItem)}>
              Update
            </Button>
            <Button
              onClick={() =>
                generatePdf({
                  title,
                  headers: [
                    ["description", "price", "payer", "list of payer"],
                    [
                      "Name",
                      "Total",
                      "Already Pay",
                      "Should Pay",
                      "Net Pay or Receive",
                    ],
                  ],
                  datas: [
                    listOfItem.map((liIt) => {
                      const {
                        description: itemDesc,
                        price: itemPrice,
                        payer: itemPayer,
                        listOfPayer: itemListOfPayer,
                      } = liIt;
                      return [
                        itemDesc,
                        `$${itemPrice}`,
                        itemPayer.map((pa) => pa.value).join(", "),
                        itemListOfPayer
                          .map((pa) => pa.value)
                          .sort()
                          .join(", "),
                      ];
                    }),
                    people.map((peo, index) => {
                      //   const { value } = peo;
                      const shouldPay = totalEachShouldPaid[peo];
                      const alreadyPay = totalEachPaid[peo];
                      var netPay = get2DigitNumber(alreadyPay - shouldPay);
                      netPay =
                        netPay < 0 ? `- $${Math.abs(netPay)}` : `$${netPay}`;
                      return [
                        peo,
                        "$" + total,
                        "$" + alreadyPay,
                        "$" + shouldPay,
                        netPay,
                      ];
                    }),
                  ],
                  subtitles: ["Item Report", "Summary Report"],
                })
              }
            >
              Export as PDF
            </Button>
          </div>
        </div>
      </div>
      <div>
        <TripCostStats
          people={people}
          totalEachPaid={totalEachPaid}
          totalEachShouldPaid={totalEachShouldPaid}
          total={total}
        />
      </div>
      {/* <div>{historyRecords.map((his) => {})}</div> */}
    </div>
  );
}
