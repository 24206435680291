import { useContext, useEffect, useState } from "react";
import { Table, Form } from "react-bootstrap";
import musicMetadataDB from "../storage/musicMetadataDB";
import musicService from "../services/musicService";
import { Button, ProgressBar } from "react-bootstrap";
import AppContext from "../context/app-context";
import audioStorage from "../storage/audioStorage";
import axios from "axios";
import { FcDownload, FcLike, FcLikePlaceholder } from "react-icons/fc";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { BsFillTrash3Fill } from "react-icons/bs";
import CustomTable from "../components/customTable";
import InfiniteScroll from "react-infinite-scroll-component";
import { generateRandomId } from "../utils/helperFunctions";
import { toast } from "react-toastify";

const MAX_DOWNLOAD_SONG_COUNT = 5;

export default function SongHomePage() {
  const [songList, setSongList] = useState([]);
  const [favorite, setFavorite] = useState([]);
  const [favoriteIds, setFavoriteIds] = useState([]);
  const [showFavorite, setShowFavorite] = useState(false);
  const [refresh, setRefresh] = useState(0);

  const { setLoading, downloadedSongs, fetchDownloadedSongs, setPlayingSongs, playSongByIndex, userProfile } =
    useContext(AppContext);
  const [query, setQuery] = useState("");
  const [downloadStatus, setDownloadStatus] = useState({});
  const [downloadList, setDownloadList] = useState([]);
  const [downloadingSongs, setDownloadingSongs] = useState([]);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState("");
  const [lastEvaluatedKeyForFavorite, setLastEvaluatedKeyForFavorite] = useState("");
  const [canLoadMoreFa, setCanLoadMoreFa] = useState(true);
  const [canLoadMore, setCanLoadMore] = useState(true);
  const [loadmore, setLoadMore] = useState(0);

  const playItem = (song) => {
    const { id, title } = song;
    const findIndex = downloadedSongs.findIndex((item) => item.id === id);
    if (findIndex >= 0) {
      playSongByIndex && playSongByIndex(findIndex);
      toast.info(`Playing ${title} now`);
    } else {
      toast.info(`Please download ${title} first`);
    }
  };

  useEffect(() => {
    musicService.getSongsByTitle({
      callback: ({ songs = [], lastEvaluatedKey, err }) => {
        const newSongList = [...songList];
        for (var i = 0; i < songs.length; i++) {
          const song = songs[i];
          if (newSongList.find((item) => item.id === song.id)) {
            setCanLoadMore(false);
            console.log("**** already exist", song, songs);
            break;
          } else {
            newSongList.push(song);
          }
        }
        setSongList(newSongList);
        setLastEvaluatedKey(lastEvaluatedKey);
      },
      title: "",
      lastEvaluatedKey,
    });
  }, [loadmore]);

  useEffect(() => {
    if (userProfile) {
      const { userId } = userProfile;
      musicService.getSongsByPlaylist({
        callback: ({ songs = [], lastEvaluatedKey: prevId, playlistIds = [], err }) => {
          const newSongList = [...favorite, ...songs];
          if (songs.length === 0) {
            setCanLoadMoreFa(false);
          }
          setFavorite(newSongList);
          setLastEvaluatedKeyForFavorite(prevId);
          setFavoriteIds(playlistIds);
        },
        id: userId,
        lastEvaluatedKey: lastEvaluatedKeyForFavorite,
      });
    }
  }, [userProfile, refresh]);

  async function deleteSongFromLocal(id) {
    await audioStorage.deleteAudioBlob(id);
    await musicMetadataDB.songs.delete(id);
    fetchDownloadedSongs({ deleteSongs: true });
  }
  function downloadSong(song) {
    const {
      attachment: { bucket, key },
      title,
    } = song;
    musicService.getPresignedDownloadUrl({
      bucket,
      key,
      callback: ({ url }) => {
        console.log("downloading " + title);
        handleDownload(url, song);
      },
    });
  }
  function getDisplayList() {
    return showFavorite ? favorite : songList;
  }
  const handleDownload = async (url, song) => {
    const { id } = song;
    try {
      const response = await axios.get(url, {
        responseType: "blob",
        onDownloadProgress: (progressEvent) => {
          let percentCompleted = Math.floor((progressEvent.loaded / progressEvent.total) * 100);
          if (Math.random() < 0.3) {
            setDownloadStatus((prev) => ({ ...prev, [id]: percentCompleted }));
          }
        },
      });
      const blob = new Blob([response.data], { type: "audio/mpeg" });
      const newStatus = Object.keys(downloadStatus)
        .filter((key) => key === id)
        .map((key) => downloadStatus[key]);
      setDownloadStatus(newStatus);
      setDownloadingSongs(downloadingSongs.filter((id) => id === id));
      const blobUrl = URL.createObjectURL(blob);
      await audioStorage.saveAudioBlob(song.id, blob);
      const newSong = {
        id: song.id,
        title: song.title,
        artist: song.artist,
        album: song.album,
        downloadedDate: new Date().toISOString(),
      };

      await musicMetadataDB.songs.add(newSong);

      fetchDownloadedSongs({ deleteSongs: true });
      setPlayingSongs([{ ...newSong, src: blobUrl }]);
    } catch (error) {
      console.error("Error downloading song:", error);
    }
  };
  useEffect(() => {
    if (downloadingSongs.length < MAX_DOWNLOAD_SONG_COUNT && downloadList.length > 0) {
      const next = downloadList.shift();
      setDownloadingSongs([...downloadingSongs, next]);
      setDownloadList(downloadList);
      downloadSong(next);
    }
  }, [downloadingSongs]);
  function checkIfDownloaded(songId) {
    return downloadedSongs.filter((song) => song.id === songId).length != 0;
  }
  const displayHeaders = ["Title", "Artist", "Favorite", "Download"];

  const displayRows = getDisplayList().map((song) => {
    const { title, artist, album, id } = song;
    const alreadyDownLoad = checkIfDownloaded(id);
    const isFavorite = favoriteIds.includes(id);
    const isPending = downloadList.find((song) => song.id === id);
    const downloadDiv = (
      <div>
        {isPending && <div>Pending</div>}
        {Object.keys(downloadStatus).includes(id) && (
          <div style={{ width: 30, height: 30 }}>
            <CircularProgressbar value={downloadStatus[id]} text={`${downloadStatus[id]}%`} />
          </div>
        )}
        {!Object.keys(downloadStatus).includes(id) && !isPending && (
          <div>{alreadyDownLoad ? <BsFillTrash3Fill /> : <FcDownload />}</div>
        )}
      </div>
    );
    return [
      { value: <div id={id}>{title}</div>, onClick: () => playItem(song) },
      { value: artist, onClick: () => playItem(song) },
      {
        value: !isFavorite ? <FcLikePlaceholder /> : <FcLike />,
        onClick: () => {
          if (!userProfile) {
            alert("You need to login first");
            return;
          }
          const { userId } = userProfile;
          setLoading(true);
          const callBackRefresh = () => {
            setLoading(false);
            setRefresh(refresh + 1);
          };
          if (isFavorite) {
            musicService.deleteSongsFromPlaylist({
              id: userId,
              songId: id,
              callback: callBackRefresh,
            });
          } else {
            musicService.addSongToPlaylist({
              id: userId,
              songId: id,
              callback: callBackRefresh,
            });
          }
        },
      },
      {
        value: downloadDiv,
        onClick: () => {
          if (!Object.keys(downloadStatus).includes(id) && !isPending) {
            if (alreadyDownLoad) {
              deleteSongFromLocal(id);
            } else {
              downloadSong(song);
            }
          }
        },
      },
    ];
  });
  return (
    <div>
      <>
        <Form.Label>Search</Form.Label>
        <div style={{ display: "flex" }}>
          <Form.Control
            type="text"
            value={query}
            onChange={(e) => {
              const val = e.target.value;
              setQuery(val);
            }}
          />
          <Button
            onClick={() => {
              setLoading(true);
              musicService.getSongsByTitle({
                callback: ({ songs = [], err }) => {
                  setSongList(songs);
                  setLoading(false);
                },
                title: query,
              });
            }}
          >
            Search
          </Button>
        </div>
      </>
      <div
        style={{
          margin: "20px 0",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "10px 0",
          }}
        >
          <Form>
            <Form.Check
              type="switch"
              value={showFavorite}
              onChange={(e) => {
                setShowFavorite(!showFavorite);
              }}
              label={"Show your Favorite songs only, required login"}
            />
          </Form>
          <Button
            onClick={() => {
              const localDownloadStatus = {};
              const localDownloadList = [];
              const localDownloadingSongs = [];
              var count = 0;
              getDisplayList().forEach((song) => {
                const { id } = song;
                if (!checkIfDownloaded(id)) {
                  // downloadSong(song);
                  if (count < MAX_DOWNLOAD_SONG_COUNT) {
                    // setDownloadingSongs
                    localDownloadingSongs.push(id);
                    localDownloadStatus[id] = 0;
                    downloadSong(song);
                  } else {
                    localDownloadList.push(song);
                  }
                  count += 1;
                }
              });
              setDownloadList(localDownloadList);
              setDownloadStatus(localDownloadStatus);
              setDownloadingSongs(localDownloadingSongs);
            }}
          >
            Download All
          </Button>
        </div>
        <div style={{ height: "50px" }}>
          <InfiniteScroll
            dataLength={displayRows.length}
            next={() => {
              if (showFavorite) {
                setRefresh(refresh + 1);
              } else {
                setLoadMore(loadmore + 1);
              }
            }}
            hasMore={showFavorite ? canLoadMoreFa : canLoadMore}
            loader={<h4>Loading...</h4>}
          >
            {displayRows.map((row = [], rowIndex) => {
              return (
                <div
                  key={generateRandomId()}
                  style={{
                    display: "flex",
                    // border: "solid",
                    backgroundColor: rowIndex % 2 == 0 ? "#d0e8f5" : "#c1e0d9",
                  }}
                >
                  {row.map((item, index) => {
                    const { value, onClick = () => {} } = item;
                    const widthSize = ["35%", "35%", "15%", "15%"];
                    return (
                      <span key={generateRandomId()} onClick={onClick} style={{ margin: "0 5px", width: widthSize[index] }}>
                        {value}
                      </span>
                    );
                  })}
                </div>
              );
            })}
          </InfiniteScroll>
          <div style={{ margin: "10px 0" }}>
            <Button
              disabled={!(showFavorite ? canLoadMoreFa : canLoadMore)}
              onClick={() => {
                if (showFavorite ? canLoadMoreFa : canLoadMore) {
                  if (showFavorite) {
                    setRefresh(refresh + 1);
                  } else {
                    setLoadMore(loadmore + 1);
                  }
                }
              }}
            >
              Load more
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
