// PieChart.js
import React from "react";
import { generateRandomId } from "../../utils/helperFunctions";
// import { Pie } from "react-chartjs-2";
// import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement } from "chart.js";

// ChartJS.register(Title, Tooltip, Legend, ArcElement);

const PieChart = ({ items }) => {
  // Process the items to calculate total amounts by category
  const categoryTotals = items.reduce((acc, item) => {
    const category = item.category || "Unknown";
    acc[category] = (acc[category] || 0) + (Number(item.amount) || 0);
    return acc;
  }, {});

  // Convert the category totals to chart.js format
  //   console.log(categoryTotals);
  const data = {
    labels: Object.keys(categoryTotals),
    datasets: [
      {
        data: Object.values(categoryTotals),
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#E8C3A0",
          "#C9DAF8",
          "#A2D9CE",
          "#F7C6C7",
          "#D5A6BD",
          "#D0E0E3",
          "#EAD1DC",
        ],
        hoverOffset: 4,
      },
    ],
  };

  return (
    <div>
      <h3>Category Distribution</h3>
      {/* <Pie data={data} /> */}
      {Object.keys(categoryTotals).map((key) => {
        const total = categoryTotals[key];
        return (
          <div key={generateRandomId()}>
            {key} - ${total.toFixed(2)}
          </div>
        );
      })}
    </div>
  );
};

export default PieChart;
