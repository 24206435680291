export const INPUT_TYPE = {
  TEXT: "TEXT",
  EMAIL: "EMAIL",
  TEXT_AREA: "TEXT_AREA",
  SELECT: "SELECT",
  FILE: "FILE",
  DATE: "DATE",
  NUMBER: "NUMBER",
  BOOLEAN: "BOOLEAN",
};
