import { useEffect, useState } from "react";
import axios from "axios";
import CollectionList from "../components/collectionList";
import VideoPlayer from "../components/videoPlayer";
import { generateRandomId } from "../utils/helperFunctions";
import { BsFillTrash3Fill } from "react-icons/bs";
import { FcDownload } from "react-icons/fc";
import { CircularProgressbar } from "react-circular-progressbar";
import movieService from "../services/movieService";
import { useNavigate } from "react-router-dom";
import metadataDB from "../storage/metadataDB";

export const BACKEND_URL = "http://192.168.86.32:1212";
function MovieListPage() {
  const [onlineMovies, setonlineMovies] = useState([]);
  const [offlineMovies, setofflineMovies] = useState([]);
  const navigate = useNavigate();
  async function getLocalMovies() {
    const items = await metadataDB.getLocalMovieList();
    setofflineMovies(items);
  }
  useEffect(() => {
    // async function getFolders() {
    //   console.log("getting movies");
    //   return axios
    //     .get(BACKEND_URL + "/movies")
    //     .then((res) => {
    //       const {
    //         data: { movies },
    //       } = res;
    //       console.log(movies);
    //       setonlineMovies(movies);
    //       return movies;
    //     })
    //     .catch((e) => {
    //       console.log(e);
    //     });
    // }
    // getFolders();
    getLocalMovies();
    movieService.getMoviesByType({
      callback: ({ movies }) => {
        if (!movies) {
          return;
        }
        setonlineMovies(movies);
      },
    });
  }, []);

  return (
    <div className="container">
      <div>
        <h3>Online movies</h3>
        <CollectionList
          collections={onlineMovies.map((item) => {
            const { title } = item;
            return {
              ...item,
            };
          })}
          onClick={(e) => {
            const { movieId } = e;
            navigate(`/movie/${movieId}`);
          }}
        />
      </div>
      <div>
        <h3>Downloaded movies</h3>
        <CollectionList
          collections={offlineMovies.map((item) => {
            const { title } = item;
            return {
              ...item,
            };
          })}
          onClick={(e) => {
            const { movieId } = e;
            navigate(`/movie/${movieId}`);
          }}
        />
      </div>
    </div>
  );
}

export default MovieListPage;
