import React from "react";
import { Card, Col, Row } from "react-bootstrap";

import ExpenseList from "../ExpenseList";
import PieChart from "./PieChart";

const MonthView = ({ date, expensesItems, setViewMode, setCurrentDate }) => {
  const year = date.getFullYear();
  const month = date.getMonth();

  const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const daysInMonth = getDaysInMonth(year, month);
  const daysArray = Array.from({ length: daysInMonth }, (_, i) => new Date(year, month, i + 1));

  let monthExpenseItems = [];
  const totalMonthAmount = daysArray.reduce((tol, day) => {
    const formattedDate = day.toISOString().split("T")[0];
    const itemsForDay = expensesItems.filter((item) => item.purchasedDate === formattedDate);
    monthExpenseItems = [...monthExpenseItems, ...itemsForDay];
    const totalAmount = itemsForDay.reduce((total, item) => total + Number(item.amount || 0), 0);
    return tol + totalAmount;
  }, 0);

  return (
    <div>
      <h3>
        {date.toLocaleString("default", { month: "long" })} {year} - Total: ${totalMonthAmount.toFixed(2)}
      </h3>
      <Row>
        {daysArray.map((day) => {
          const formattedDate = day.toISOString().split("T")[0];
          const itemsForDay = expensesItems.filter((item) => item.purchasedDate === formattedDate);
          const totalAmount = itemsForDay.reduce((total, item) => total + Number(item.amount || 0), 0);

          if (totalAmount === 0) {
            return;
          }
          return (
            <Col key={day} xs={6} sm={4} md={3} lg={2}>
              <Card
                className="mb-3"
                onClick={() => {
                  setViewMode("day");
                  setCurrentDate(day);
                }}
                style={{ cursor: "pointer" }}
              >
                <Card.Body className="text-center">
                  <p>{day.toDateString()}</p>
                  <p>Total: ${totalAmount.toFixed(2)}</p>
                </Card.Body>
              </Card>
            </Col>
          );
        })}
      </Row>
      <Row className="mt-4">
        <PieChart items={monthExpenseItems} />
      </Row>
      <Row>
        <ExpenseList items={monthExpenseItems} defaultOpen={false} />
      </Row>
    </div>
  );
};

export default MonthView;
