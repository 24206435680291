import React from "react";
import { Card, Col, Row } from "react-bootstrap";

import ExpenseList from "../ExpenseList";
import PieChart from "./PieChart";

const WeekView = ({ date, expensesItems, setViewMode, setCurrentDate }) => {
  const startOfWeek = (date) => {
    const day = date.getDay();
    const diff = date.getDate() - day + (day === 0 ? -6 : 1);
    return new Date(date.setDate(diff));
  };

  const getWeekDays = (date) => {
    const start = startOfWeek(new Date(date));
    return Array.from({ length: 7 }, (_, i) => new Date(start.getTime() + i * 86400000));
  };

  const weekDays = getWeekDays(date);

  // Calculate the total amount for the entire week
  const totalWeekAmount = weekDays.reduce((total, day) => {
    const formattedDate = day.toISOString().split("T")[0];
    const itemsForDay = expensesItems.filter((item) => item.purchasedDate === formattedDate);
    const dailyTotal = itemsForDay.reduce((dayTotal, item) => dayTotal + Number(item.amount || 0), 0);
    return total + dailyTotal;
  }, 0);

  let filteredItems = [];
  const totalAmountForWeek = weekDays.reduce((tol, day) => {
    const formattedDate = day.toISOString().split("T")[0];
    const itemsForDay = expensesItems.filter((item) => item.purchasedDate === formattedDate);
    filteredItems = [...filteredItems, ...itemsForDay];
    const totalAmount = itemsForDay.reduce((total, item) => total + Number(item.amount || 0), 0);
    return tol + totalAmount;
  }, 0);
  return (
    <div>
      <h3>Week View - Total: ${totalAmountForWeek.toFixed(2)}</h3>
      <Row>
        {weekDays.map((day) => {
          const formattedDate = day.toISOString().split("T")[0];
          const itemsForDay = expensesItems.filter((item) => item.purchasedDate === formattedDate);
          const totalAmount = itemsForDay.reduce((total, item) => total + Number(item.amount || 0), 0);

          if (totalAmount === 0) {
            return;
          }
          return (
            <Col key={day} xs={6} sm={4} md={3} lg={2}>
              <Card
                className="mb-3"
                onClick={() => {
                  setViewMode("day");
                  setCurrentDate(day);
                }}
                style={{ cursor: "pointer" }}
              >
                <Card.Body className="text-center">
                  <p>{day.toDateString()}</p>
                  <p>Total: ${totalAmount.toFixed(2)}</p>
                </Card.Body>
              </Card>
            </Col>
          );
        })}
      </Row>
      <Row className="mt-4">
        <PieChart items={filteredItems} />
      </Row>
      <Row>
        <ExpenseList items={filteredItems} defaultOpen={false} />
      </Row>
    </div>
  );
};

export default WeekView;
