// db.js
import Dexie from "dexie";
import localFileStorage from "./localFileStorage";

const metadataDB = new Dexie("mediaMetadataDB");

metadataDB.version(1).stores({
  movieMetaData:
    "&movieId, title, description, type, lastModifiedDate, insertedDate",
  movieEpisodeData: "&episodeId, title, movieId",
  playHistory:
    "&id, pageNumber, lastModifiedDate, novelId, currentTime, duration",
});

async function putItemToMovieMetadata(item) {
  const insertedDate = new Date().toISOString();
  return metadataDB.movieMetaData.put({ ...item, insertedDate });
}

async function putItemToEpisodeData(item) {
  const insertedDate = new Date().toISOString();
  return metadataDB.movieEpisodeData.put({ ...item, insertedDate });
}

async function putItemToPlayHistory(item) {
  const insertedDate = new Date().toISOString();
  return metadataDB.playHistory.put({ ...item, insertedDate });
}

async function getMovieMetadataById(movieId) {
  const metadataItem = await metadataDB.movieMetaData.get(movieId);
  return { ...metadataItem };
}

// async function getNovelPageMetaById(id) {
//   return metadataDB.novelPageData.get(id);
// }

function customSort(a, b) {
  const nameA = a.name;
  const nameB = b.name;
  const numA = parseFloat(nameA);
  const numB = parseFloat(nameB);

  if (!isNaN(numA) && !isNaN(numB)) {
    if (numA !== numB) {
      return numA - numB;
    } else {
      return nameA.localeCompare(nameB);
    }
  } else if (!isNaN(numA)) {
    return -1;
  } else if (!isNaN(numB)) {
    return 1;
  } else {
    return nameA.localeCompare(nameB);
  }
}

async function getMovieEpisodeById(id) {
  const expisodes = await metadataDB.movieEpisodeData
    .where("movieId")
    .equalsIgnoreCase(id)
    .toArray();
  expisodes.sort(customSort);
  return expisodes;
}

async function getLocalMovieList() {
  const localList = await metadataDB.movieMetaData.toArray();
  const retList = await Promise.all(
    localList.map(async (item) => {
      const { movieId } = item;
      const downloadedEpisodes = await getMovieEpisodeById(movieId);
      if (downloadedEpisodes.length > 0) {
        return { ...item, downloadedEpisodes };
      }
      return null;
    })
  );
  return retList.filter((item) => item !== null);
}

async function deleteAllCaches() {
  const movieMetaData = (await metadataDB.movieMetaData.toArray()) || [];
  const movieEpisodeData = (await metadataDB.movieEpisodeData.toArray()) || [];
  // const playHistory = (await metadataDB.playHistory.toArray()) || [];
  movieMetaData.forEach((page) => {
    const { movieId } = page;
    metadataDB.movieMetaData.delete(movieId);
  });
  movieEpisodeData.forEach((nov) => {
    const { episodeId } = nov;
    localFileStorage.deleteBlobFromLocal(episodeId);
    deleteItemFromEpisodeData(episodeId);
  });
  return movieMetaData.length + movieEpisodeData.length;
}

async function getLocalPlayHistory(id) {
  if (id) {
    return metadataDB.playHistory
      .where("movieId")
      .equalsIgnoreCase(id)
      .toArray();
  }
  return metadataDB.playHistory.toArray();
}

async function deleteItemFromEpisodeData(id) {
  return metadataDB.movieEpisodeData.delete(id);
}

// async function deleteItemFromNovelHistory(id) {
//   return metadataDB.playHistory.delete(id);
// }

export default {
  putItemToMovieMetadata,
  putItemToEpisodeData,
  putItemToPlayHistory,
  getMovieEpisodeById,
  getLocalMovieList,
  getLocalPlayHistory,
  deleteItemFromEpisodeData,
  // deleteItemFromNovelHistory,
  getMovieMetadataById,
  deleteAllCaches,
  // getNovelPageMetaById,
};
