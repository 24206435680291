import { Table } from "react-bootstrap";
import { generateRandomId, get2DigitNumber } from "../utils/helperFunctions";

export default function TripCostStats(props) {
  const { people, totalEachShouldPaid, totalEachPaid, total } = props;
  // const total = listOfItem.reduce((accumulator, currentItem) => {
  //   const { price } = currentItem;
  //   return Number(accumulator) + Number(price);
  // }, 0);
  // const totalEachPaid = {};
  // const totalEachShouldPaid = {};
  // people.forEach((it) => {
  //   totalEachPaid[it] = 0;
  //   totalEachShouldPaid[it] = 0;
  // });
  // listOfItem.forEach((item) => {
  //   const {
  //     payer: [{ value }],
  //     listOfPayer = [],
  //     price,
  //   } = item;
  //   totalEachPaid[value] = totalEachPaid[value] + Number(price);
  //   const eachPay =
  //     Math.round((Number(price) / listOfPayer.length) * 100) / 100;
  //   listOfPayer.forEach((itemPayer) => {
  //     const { value: itemVal } = itemPayer;
  //     totalEachShouldPaid[itemVal] += eachPay;
  //   });
  // });
  return (
    <div style={{ margin: "10px", border: "3px solid grey", padding: "10px" }}>
      <h2>Trip Cost Stats</h2>
      <div>
        <Table responsive borderless striped hover size="sm">
          <thead>
            <tr>
              <th>Member</th>
              <th>Total</th>
              <th>AlreadyPay</th>
              <th>Should Pay</th>
              <th>Net Pay or Receive</th>
            </tr>
          </thead>
          <tbody>
            {people.map((peo, index) => {
              //   const { value } = peo;
              const shouldPay = totalEachShouldPaid[peo];
              const alreadyPay = totalEachPaid[peo];
              const netPay = get2DigitNumber(alreadyPay - shouldPay);
              return (
                <tr onClick={(e) => {}} key={generateRandomId()}>
                  <td>{peo}</td>
                  <td>${total}</td>
                  <td>${alreadyPay}</td>
                  <td>${shouldPay}</td>
                  <td>${netPay}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
}
