import axios from "axios";
import { openDB } from "idb";

const DB_NAME = "NovelAppAudio";
const LOCAL_FILE_STORAGE = "LOCAL_FILE_STORAGE";

async function getLocalFileDb() {
  return openDB(DB_NAME, 1, {
    upgrade(db) {
      db.createObjectStore(LOCAL_FILE_STORAGE);
    },
  });
}

async function saveBlobToLocal(id, blob) {
  const db = await getLocalFileDb();
  return db.put(LOCAL_FILE_STORAGE, blob, id);
}

async function getBlobFromLocal(id) {
  const db = await getLocalFileDb();
  return db.get(LOCAL_FILE_STORAGE, id);
}
async function getBlobUrlFromLocal(id) {
  const db = await getLocalFileDb();
  const file = await db.get(LOCAL_FILE_STORAGE, id);
  if (file) return URL.createObjectURL(file);
  return false;
}

async function deleteBlobFromLocal(id) {
  const db = await getLocalFileDb();
  return db.delete(LOCAL_FILE_STORAGE, id);
}

const handleDownload = async (url, type, onDownloadCallback) => {
  try {
    const response = await axios.get(url, {
      responseType: "blob",
      onDownloadProgress: (progressEvent) => {
        let percentCompleted = Math.floor(
          (progressEvent.loaded / progressEvent.total) * 100
        );
        onDownloadCallback && onDownloadCallback(percentCompleted);
      },
    });
    const blobData = new Blob([response.data], { type: type });
    return blobData;
  } catch (error) {
    console.error("Error downloading url:" + url, error);
  }
};

const getFileOrDownloadedUrl = async (
  id,
  fromUrl,
  type,
  onDownloadCallback = () => {}
) => {
  const file = await getBlobFromLocal(id);
  var downloadedUrl = "";
  if (!file) {
    function onDownloadProgress(precentage) {
      onDownloadCallback({ id, precentage });
    }
    const blob = await handleDownload(fromUrl, type, onDownloadProgress);
    if (blob) {
      await saveBlobToLocal(id, blob);
      downloadedUrl = URL.createObjectURL(blob);
    }
  } else {
    downloadedUrl = URL.createObjectURL(file);
  }
  return downloadedUrl;
};

export default {
  saveBlobToLocal,
  getBlobFromLocal,
  deleteBlobFromLocal,
  getFileOrDownloadedUrl,
  getBlobUrlFromLocal,
};
