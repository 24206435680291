import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AppContext from "../context/app-context";
import { getUserJwtToken } from "../utils/helperFunctions";
import { FileUploader } from "react-drag-drop-files";
import { Alert, Button, ProgressBar } from "react-bootstrap";
import musicService from "../services/musicService";
import { toast } from "react-toastify";

export function UploadSongPage(props) {
  const fileTypes = ["mp3"];
  const { setLoading } = useContext(AppContext);
  const [refresh, setRefresh] = useState(0);
  const [attchements, setAttchements] = useState([]);
  const [uploadStatus, setUploadStatus] = useState({});
  const [songTitle, setSongTitle] = useState("");
  const [songArtist, setSongArtist] = useState("");
  const [songAlbum, setSongAlbum] = useState("");
  const [youtubeUrl, setyoutubeUrl] = useState("");
  const [uploadedItem, setuploadedItem] = useState([]);
  useEffect(() => {
    // setLoading(true);
  }, [refresh]);
  const filesUploadCallback = ({ id, value }) => {
    console.log(`${id} uploaded ${value}%`);
    uploadStatus[id] = value;
    console.log(uploadStatus);
    setUploadStatus({ ...uploadStatus });
  };
  return (
    <div>
      <div>
        <div>
          {/* <Alert key={"info"} variant={"info"}></Alert>
          <p>
            You can download the songs from youtube using{" "}
            <a
              href="https://en1.onlinevideoconverter.pro/10/youtube-music-downloader"
              target={"_blank"}
            >
              onlinevideoconverter
            </a>{" "}
            and upload to the system. Login first then upload the song then the
            song will automatically added to your favriote list! Then you can
            download it and play it offline
          </p> */}
        </div>
        <h2>Enter song information</h2>
        <label>
          Title:
          <input type="text" value={songTitle} onChange={(e) => setSongTitle(e.target.value)} />
        </label>
        <label>
          Artist:
          <input type="text" value={songArtist} onChange={(e) => setSongArtist(e.target.value)} />
        </label>
        <label>
          Album:
          <input type="text" value={songAlbum} onChange={(e) => setSongAlbum(e.target.value)} />
        </label>
        {/* <label>
          YoutubeUrl:
          <input type="text" value={youtubeUrl} onChange={(e) => setyoutubeUrl(e.target.value)} />
        </label> */}
        <div>
          {attchements.map((attchement) => {
            const { name, type } = attchement;
            const status = uploadStatus[name];
            return (
              <div key={name}>
                <label>{`fileName: ${name}`}</label>
                <br />
                {status ? <ProgressBar now={status} label={`${status}%`} /> : "Ready to upload"}
              </div>
            );
          })}
        </div>
        <div style={{ margin: "10px" }} />
        <FileUploader
          handleChange={(file) => {
            // const { name, type } = files[0];
            // console.log(file);
            setAttchements([file]);
          }}
          multiple={false}
          hoverTitle={"Drop the videos here"}
          types={fileTypes}
        />
        <div style={{ margin: "10px" }} />
        <Button
          variant="primary"
          onClick={() => {
            setLoading(true);
            if (!songTitle || (!youtubeUrl.includes("youtube.com") && attchements.length === 0)) {
              toast.error("Please make sure enter title and youtube url or mp3 file.");
              return;
            }
            musicService.createSongMetadata({
              callback: ({ data }) => {
                console.log(data);
                setuploadedItem((prev) => {
                  prev.push({ songTitle, songArtist });
                  return prev;
                });
                toast.info(`${songTitle} uploaded sucessfully, please go to online song to download.`);
                setAttchements([]);
                setSongTitle("");
                setSongArtist("");
                setSongAlbum("");
                setyoutubeUrl("");
                setLoading(false);
              },
              title: songTitle,
              album: songAlbum,
              artist: songArtist,
              youtubeUrl,
              files: attchements,
              filesUploadCallback,
            });
          }}
        >
          Upload
        </Button>
      </div>
      <div>
        {uploadedItem.map((item) => {
          const { songTitle, songArtist } = item;
          return (
            <div key={songTitle}>
              {" "}
              {songTitle} - {songArtist} uploaded{" "}
            </div>
          );
        })}
      </div>
    </div>
  );
}
