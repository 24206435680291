import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AppContext from "../context/app-context";
import Card from "react-bootstrap/Card";
import { Alert } from "react-bootstrap";
import { APP_ROUTE_LIST } from "../App";
import CollectionList from "../components/collectionList";
import { Form } from "react-bootstrap";
const examPath = "/practice-Exam/";
export const PRACTICE_EXAM_CONTENTS = [];

export default function HomePage(props) {
  const { userProfile, setLoading, examHistory } = useContext(AppContext);
  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  return (
    <div>
      <div>
        {/* <Alert key={"primary"} variant={"primary"}>
          Announcement: We are planning to start taking AP CS free response
          question and give feedback on the answer, feel free to check us back
          later.
        </Alert> */}
      </div>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {APP_ROUTE_LIST.slice(1).map((route) => {
          const { title, description, path } = route;
          if (!title) {
            return;
          }
          return (
            <div key={title} style={{ margin: "10px" }}>
              <Card style={{ width: "18rem" }}>
                <Link key={path} to={path}>
                  <Card.Body>
                    <Card.Title>{title}</Card.Title>
                    <p>{`${description}`}</p>
                  </Card.Body>
                </Link>
              </Card>
            </div>
          );
        })}
        <div style={{ margin: "10px 0" }}>
          <>
            <Form.Label>查找</Form.Label>
            <Form.Control
              type="text"
              value={query}
              onChange={(e) => {
                const val = e.target.value;
                setQuery(val);
              }}
            />
            <Form.Text></Form.Text>
          </>
        </div>
        <CollectionList collections={[]} query={query} />
      </div>
      <div>
        <Alert key={"info"} variant={"info"}>
          Note: You can save this website offline, which mean you can access
          some of the feature offline, including the music you downloaded. you
          can do this by: For Google Chrome (Android and iOS): Open the Chrome
          app on your mobile phone. Navigate to the website you want to save for
          offline viewing. Tap the three-dot menu icon in the top-right corner
          of the screen. Select "Download" from the menu. The webpage will be
          downloaded and saved to your device for offline viewing. For Safari
          (iOS): Open the Safari app on your iPhone or iPad. Navigate to the
          website you want to save for offline viewing. Tap the Share icon (a
          box with an arrow pointing upward) at the bottom of the screen. Scroll
          through the options and tap "Add to Reading List." The webpage will be
          saved for offline viewing in the Reading List.
        </Alert>
      </div>
    </div>
  );
}
